import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NotificationsService } from '../notifications.service';
import { CreatorsNotification, CreatorsNotificationType, CreatorsNotificationUI } from '../notifications';
import { Subscription } from 'rxjs';
import { BodyService } from '../../shared/body.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { PixelsService } from 'app/shared/pixels.service';
import { NotificationType } from 'app/shared/notifications';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: false
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public isSidebarOpen: boolean;
  @ViewChild('wrap') wrap: ElementRef;
  private subs: Array<Subscription>;
  private lastTS: number;
  constructor(
    public notificationsService: NotificationsService,
    public body: BodyService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private pixels: PixelsService
  ) {
    this.isSidebarOpen = this.notificationsService.getSidebarState();
    this.notificationsService.onSidebarToggle.subscribe((state) => {
      setTimeout(() => {
        this.isSidebarOpen = state;
      });
    });
    this.lastTS = new Date().getTime();
    this.subs = [];
  }

  ngOnInit() {
    this.subs.push(this.notificationsService.onNotification.subscribe(this.onAnnouncement.bind(this)));
    this.subs.push(this.broadcaster.on('popstate').subscribe((e: any) => {
      if (this.isSidebarOpen) {
        // e.preventDefault();
        history.pushState(null, null, window.location.pathname);
        history.pushState(null, null, window.location.pathname);
        this.notificationsService.toggleSidebar(false);
      }
    }));
    this.broadcaster.on('onclick').subscribe(
      (elm: Element) => {
        if (this.isSidebarOpen) {
          if (!this.utils.closest(elm, 'app-notifications') && !this.utils.closest(elm, '.toggle-notifications'))
            this.toggleSidebar();
        }
      }
    );
  }

  onAnnouncement(n: CreatorsNotification) {
    switch (n.notification_type) {
      case CreatorsNotificationType.CUSTOM_BROADCAST: {
        this.showCustomBroadcast(n);
        break;
      }
      case CreatorsNotificationType.PAYMENT_APPROVED: {
        this.utils.notifyUser({ text: 'Payment was approved', type: NotificationType.Success });
        break;
      }
      case CreatorsNotificationType.PAYMENT_CANCELED: {
        this.utils.notifyUser({ text: 'Payment was denied', type: NotificationType.Error });
        break;
      }
    }
  }

  getTitleByNotification(n: CreatorsNotification) {
    switch (n.notification_type) {
      case CreatorsNotificationType.FEEDBACK_RECEIVED: {
        return 'You\'ve got a new feedback';
      }
      case CreatorsNotificationType.JOB_ASSIGNED: {
        return 'You\'ve got a new job';
      }
      case CreatorsNotificationType.CUSTOM_BROADCAST: {
        return 'Announcement';
      }
      case CreatorsNotificationType.JOB_DUE_SOON: {
        return 'A job is due soon';
      }
      case CreatorsNotificationType.PRIVATE_OFFER: {
        return 'You\'ve got a private offer';
      }
      case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
        return 'A new offer is available';
      }
      case CreatorsNotificationType.FEEDBACK_COMMENT: {
        return 'A feedback has a new comment';
      }
      case CreatorsNotificationType.FEEDBACK_WAITING_FOR_REVIEW: {
        return 'A feedback is waiting for your review';
      }
      case CreatorsNotificationType.PRIVATE_OFFER_HAS_BEEN_TAKEN: {
        return 'A private offer has been taken';
      }
      case CreatorsNotificationType.OFFER_HAS_UPDATED_ITS_PRICING: {
        return 'An offer has updated its pricing';
      }
      case CreatorsNotificationType.ARTIST_HAS_GROUP_PRIVATE_OFFER: {
        return 'You\'ve got new private offers';
      }
      case CreatorsNotificationType.NEW_JOB_COMMENT: {
        return 'You have a new job comment';
      }
      case CreatorsNotificationType.SIMILAR_ITEM: {
        return 'A new offer might interest you';
      }
      case CreatorsNotificationType.PAYMENT_APPROVED: {
        return `<b>Payment approved</b> - ${n.notes}`;
      }
      case CreatorsNotificationType.PAYMENT_CANCELED: {
        return `<b>Payment declined</b> - ${n.notes}`;
      }
      case CreatorsNotificationType.TRUST_LEVEL_INCREASED: {
        return `Increase: Your Trust Level has increased to ${n.notes}`;
      }
      case CreatorsNotificationType.TRUST_LEVEL_DECREASED: {
        return `Decrease: Your Trust Level has decreased to ${n.notes}`;
      }
      case CreatorsNotificationType.SOURCE_FILE_MATCH: {
        return 'Your source files have been validated and your job is approved';
      }
      case CreatorsNotificationType.SOURCE_FILE_NO_MATCH: {
        return 'One of your source files does not match the approved files, please upload new source files to get paid';
      }
      case CreatorsNotificationType.ONE_SOURCE_FILE_MATCH: {
        return 'One of your source files has been validated';
      }
      case CreatorsNotificationType.ONE_SOURCE_FILE_NO_MATCH: {
        return 'One of your source files does not match the approved files, please upload new source files to get paid';
      }
    }
  }

  toggleSidebar() {
    this.notificationsService.toggleSidebar(!this.notificationsService.getSidebarState());
  }

  // getIcon(n: CreatorsNotification) {
  //   switch (n.notification_type) {
  //     case CreatorsNotificationType.FEEDBACK_RECEIVED: {
  //       return 'assignment_late';
  //     }
  //     case CreatorsNotificationType.JOB_ASSIGNED: {
  //       return 'assignment';
  //     }
  //     case CreatorsNotificationType.CUSTOM_BROADCAST: {
  //       return 'announcement';
  //     }
  //     case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
  //       return 'local_offer';
  //     }
  //     default: {
  //       return 'announcement';
  //     }
  //   }
  // }

  markAsRead(n: CreatorsNotificationUI) {
    n.read = true;
    this.notificationsService.markAsRead(n.id);
  }

  navToOffer(n: CreatorsNotificationUI) {
    let url = n.url;
    if (url) {
      let ref = this.utils.getUrlParam(n.url, 'ref');
      let path = this.utils.getPath(n.url);
      path = path.substring(7);
      if (path.indexOf('/') == path.length)
        path = path.substring(0, path.length - 1);
      // url = '/offer/' + path + '?ref=PRIVATE_OFFER';
      url = '/offer/' + path;
      if (ref)
        url = url + '?ref=' + ref;
    }
    else
      // url = '/offer/' + n.reference_id + '?ref=PRIVATE_OFFER';
      url = '/offer/' + n.reference_id;
    this.utils.forceRedirectTo(url);
  }

  action(n: CreatorsNotificationUI) {
    switch (n.notification_type) {
      case CreatorsNotificationType.FEEDBACK_RECEIVED: {
        this.utils.forceRedirectTo('/jobs/feedbacks/' + n.reference_id + '/' + n.artist_user_id + '?ref=FEEDBACK_RECEIVED');
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickFeedbackReceived',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.OFFER_HAS_UPDATED_ITS_PRICING: {
        this.navToOffer(n);
        this.markAsRead(n);
        break;
      }
      case CreatorsNotificationType.PRIVATE_OFFER_HAS_BEEN_TAKEN: {
        // this.utils.forceRedirectTo('/job/' + n.reference_id + '?ref=FEEDBACK_RECEIVED');
        this.utils.forceRedirectTo('/jobs/job/' + n.reference_id);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickFeedbackReceived',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.JOB_ASSIGNED: {
        this.utils.forceRedirectTo('/jobs/job/' + n.reference_id);
        this.markAsRead(n);
        break;
      }
      case CreatorsNotificationType.JOB_DUE_SOON: {
        // this.utils.forceRedirectTo('/job/' + n.reference_id + '?ref=JOB_DUE_SOON');
        this.utils.forceRedirectTo('/jobs/job/' + n.reference_id);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickJobDueSoon',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.CUSTOM_BROADCAST: {
        this.showCustomBroadcast(n);
        break;
      }
      case CreatorsNotificationType.PRIVATE_OFFER: {
        // let url = n.url;
        // if (url) {
        //   let path = this.utils.getPath(n.url);
        //   path = path.substring(7);
        //   if (path.indexOf('/') == path.length)
        //     path = path.substring(0, path.length - 1);
        //   // url = '/offer/' + path + '?ref=PRIVATE_OFFER';
        //   url = '/offer/' + path;
        // }
        // else
        //   // url = '/offer/' + n.reference_id + '?ref=PRIVATE_OFFER';
        //   url = '/offer/' + n.reference_id;
        // this.utils.forceRedirectTo(url);
        this.navToOffer(n);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickPrivateOffer',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
        // let url = n.url;
        // if (url) {
        //   let path = this.utils.getPath(n.url);
        //   path = path.substring(7);
        //   if (path.indexOf('/') == path.length)
        //     path = path.substring(0, path.length - 1);
        //   // url = '/offer/' + path + '?ref=RELEVANT_PUBLIC_OFFER';
        //   url = '/offer/' + path;
        // }
        // else
        //   // url = '/offer/' + n.reference_id + '?ref=RELEVANT_PUBLIC_OFFER';
        //   url = '/offer/' + n.reference_id;
        // this.utils.forceRedirectTo(url);
        this.navToOffer(n);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickRelevantPublicOffer',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.FEEDBACK_COMMENT: {
        this.utils.forceRedirectTo('/jobs/feedbacks/' + n.reference_id + '/' + n.artist_user_id + '?ref=FEEDBACK_COMMENT');
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickFeedbackComment',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.FEEDBACK_WAITING_FOR_REVIEW: {
        // this.utils.forceRedirectTo('/job/' + n.reference_id + '?ref=FEEDBACK_WAITING_FOR_REVIEW');
        this.utils.forceRedirectTo('/jobs/job/' + n.reference_id);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickFeedbackWwitingForReview',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.ARTIST_HAS_GROUP_PRIVATE_OFFER: {
        this.utils.forceRedirectTo('offers;order_by=price;is_desc=true;public=false;relevant=false');
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickPrivateOffersGroup',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.NEW_JOB_COMMENT: {
        this.utils.forceRedirectTo(`jobs/job/${n.reference_id}`);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'notificationClickNewJobComment',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.SIMILAR_ITEM: {
        this.navToOffer(n);
        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'suggest_offer',
          reference_id: n.id
        });
        break;
      }
      case CreatorsNotificationType.SOURCE_FILE_NO_MATCH: {
        this.utils.forceRedirectTo('/jobs/job/' + n.reference_id);
        this.markAsRead(n);
        break;
      }
      case CreatorsNotificationType.ONE_SOURCE_FILE_NO_MATCH: {
        const url = new URL(n.url);
        const pathWithParams = url.pathname + url.search;
        this.utils.forceRedirectTo(pathWithParams);
        this.markAsRead(n);
        break;
      }
      case CreatorsNotificationType.ONE_SOURCE_FILE_MATCH: {
        const url = new URL(n.url);
        const pathWithParams = url.pathname + url.search;
        this.utils.forceRedirectTo(pathWithParams);
        this.markAsRead(n);
        break;
      }
    }
    this.toggleSidebar();
  }

  showCustomBroadcast(n: CreatorsNotificationUI) {
    this.notificationsService.showCustomBroadcast(n);
  }

  onScroll(evt: any) {
    let maxPercentage = 0.6;
    let total = evt.target.scrollHeight;
    let current = evt.target.scrollTop;
    let position = current / total;
    if (position > maxPercentage) {
      if (this.utils.isAboveTS(new Date().getTime(), this.lastTS, 2000)) {
        this.notificationsService.fetchMore();
        this.lastTS = new Date().getTime();
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
