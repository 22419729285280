import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CollisionService } from '../collision.service';
import { Subject, takeUntil } from 'rxjs';
import { RowStatus } from './collision-pairing-rows.model'


@Component({
    selector: 'app-collision-pairing-rows',
    templateUrl: './collision-pairing-rows.component.html',
    styleUrls: ['./collision-pairing-rows.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CollisionPairingRowsComponent implements OnInit, OnDestroy {
  @Input() disableRows: boolean;
  public rows: RowStatus[] = new Array(this.collisionService.minPoints);
  private _destroy$ = new Subject<void>();
  private _timeoutSub: number;

  constructor(private collisionService: CollisionService, private cdf: ChangeDetectorRef) {
    this.rows[0] = RowStatus.matching;
    this.rows.fill(RowStatus.empty, 1);
    collisionService.afterPointAddedSub.pipe(takeUntil(this._destroy$)).subscribe(() => this.updateRows())
    collisionService.afterRemoveAllCollisionsSub.pipe(takeUntil(this._destroy$)).subscribe(() => this.resetRows())
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroy$.next();
    clearTimeout(this._timeoutSub);
  }

  public get points2d(): number[][] {
    return this.collisionService.alignmentRequest.points_2d;
  }

  public get points3d(): number[][] {
    return this.collisionService.alignmentRequest.points_3d;
  }

  public get RowStatus(): typeof RowStatus{
    return  RowStatus;
  }

  public onMouseover(index: number): void {
    this.collisionService.highlight(index);
  }

  public onMouseout(index: number): void {
    this.collisionService.blur(index);
  }

  public removePoint(index: number): void {
    this.collisionService.removeCollisions(index);
    this.rows.splice(index, 1);
    this.rows.push(RowStatus.empty);
    this.cdf.markForCheck();
  }

  private updateRows(): void {
    const points2dIndex = this.points2d.length - 1;
    const points3dIndex = this.points3d.length - 1;

    if (points2dIndex > points3dIndex) {
      this.rows[points2dIndex] = RowStatus.matchingSecond;
    } else {
      this.rows[points2dIndex] = RowStatus.matchedTransition;
      this.setRowMatchedWithDelay(points2dIndex);
      if (this.rows[points2dIndex + 1]) {
        this.rows[points2dIndex + 1] = RowStatus.matching;
      }
    }
    this.cdf.markForCheck();
  }

  private resetRows(): void {
    this.rows[0] = RowStatus.matching;
    this.rows.fill(RowStatus.empty, 1);
    this.cdf.markForCheck();
  }

  private setRowMatchedWithDelay(rowIndex: number): void {
    this._timeoutSub = setTimeout(() => {
      this.rows[rowIndex] = RowStatus.matched;
      this.cdf.markForCheck();
    }, 500);
  }
}
