<div class="wrap alt-bg" [ngClass]="{'blur': body.isBlur, 'open': isSidebarOpen}" #wrap>
  <button mat-icon-button class="unread-all pos-abs" (click)="notificationsService.markAllAsRead()" matTooltip="mark all as read" [ngClass]="{'primary-color': notificationsService.unred}" aria-label="mark all notifications as read">
    <mat-icon>markunread_mailbox</mat-icon>
  </button>

  <h3 class="h2 text-center uppercase">notifications</h3>
  <mat-divider></mat-divider>
  <div class="inner float-dir scrollbar sidebar" [ngClass]="{'open': isSidebarOpen}" *ngIf="isSidebarOpen"
    (scroll)="onScroll($event)">

    <div *ngFor="let group of notificationsService.notificationsGroups" class="pos-rel float-dir w-100 row">
      <div class="date-wrap">
        <mat-divider></mat-divider>
        <p class="text2 alt-bg">
          {{group.created_at | moment : 'format' : 'EEEE, MMMM do' }}
        </p>
      </div>

      <div class="activity float-dir w-100 pointer" *ngFor="let item of group.items" (click)="action(item)">
        <div class="details float-dir scrollbar">
          <div>
            <span>
              <span class="status" [ngClass]="{'online': !item.read}"></span>
              {{item.created_at | moment : 'format' : 'h:mm a' }}
            </span>
          </div>
          <div class="text2" [ngClass]="{'pointer underline bold': item.onClickArgs}">
            <!-- <mat-icon>{{getIcon(item)}}</mat-icon> -->
            <div [innerHTML]="getTitleByNotification(item)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
