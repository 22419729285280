/* eslint-disable max-len */
import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { JobService } from 'app/job/job.service';
import {
  AssetAdjustmentsService,
  AssetCommunication,
  EnumsService,
  KeyValuePair,
  PostMessage
} from 'asset-adjustments';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject, takeUntil } from 'rxjs';
import { AdjustmentsPreset } from '../asset-adjustments';
import { AssetAdjustmentsHelperService } from '../asset-adjustments-helper.service';
import { ColorSamplingService } from '../color-sampling.service';
import { Notification, NotificationType } from '../../shared/notifications';
import { ArtistsJobsResourcesColorComparisonUI } from '../../job/resource';
import { debounceTime } from 'rxjs/operators';
import { AB_THRESHOLD, LIGHTNESS_THRESHOLD } from 'app/shared/enums';

@Component({
    selector: 'app-color-sampling-status',
    templateUrl: './color-sampling-status.component.html',
    styleUrls: ['./color-sampling-status.component.scss'],
    standalone: false
})
export class ColorSamplingStatusComponent implements OnInit, OnDestroy {
  @Input() iframe: ElementRef<HTMLIFrameElement>;
  @Input() model: string;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() setSelectedImage = new EventEmitter<number>();
  @Output() loaded = new EventEmitter<void>();
  public assetCommunication: AssetCommunication;
  public presets: Array<AdjustmentsPreset>;
  public sampleSize: KeyValuePair;
  public colorTestSucceeded: boolean;
  private _destroy$ = new Subject<void>();
  private _applyPosition$ = new Subject<void>();
  private _lastPixel: number;
  private _getPresetsFailedAttempts = 0;
  private _getPresetFailAttemptsThreshold = 4;

  constructor(
    public colorSamplingService: ColorSamplingService,
    public jobService: JobService,
    public aAHS: AssetAdjustmentsHelperService,
    public aAS: AssetAdjustmentsService,
    private broadcaster: BroadcasterService,
    private assetAdjustmentsService: AssetAdjustmentsService,
    private enums: EnumsService,
    private injector: Injector,
  ) {
    this.applySampleSize(colorSamplingService.alignmentPixels[3]);
    delete colorSamplingService.alignment?.config_json;
  }

  async ngOnInit() {
    this._applyPosition$.pipe(debounceTime(1000)).subscribe(this.applyAlignmentPosition);
    await this.initPresets();
    this.broadcaster.on('space').pipe(takeUntil(this._destroy$)).subscribe(this.onSpace.bind(this));
    this.assetCommunication = new AssetCommunication({ iframeModel: this.iframe, modelUrl: this.model }, this.injector);
    this.assetCommunication.eventEmitter.pipe(takeUntil(this._destroy$)).subscribe(this.onMessage.bind(this));
    delete this.colorSamplingService.alignment?.config_json;
    this.checkIfColorTestSucceeded();
    this.blurActiveElement();
  }

  ngOnDestroy() {
    this._destroy$.next();
    clearTimeout(this.colorSamplingService.positionTimeout);
  }

  public async applyPreset(preset: AdjustmentsPreset): Promise<void> {
    await this.colorSamplingService.applyPreset(preset);
  }

  public applySampleSize(sampleSize: KeyValuePair): void {
    this.sampleSize = sampleSize;
    this.colorSamplingService.pixelsTest = sampleSize.key;
  }

  public onLinkClick(): void {
    const isJpgFormat = !this.colorSamplingService.selectedPreset.preset_json.hdri.format || this.colorSamplingService.selectedPreset.preset_json.hdri.format === 'jpg';
    let hdrUrl = this.enums.getHDRIFull()[this.colorSamplingService.selectedPreset.preset_json.hdri.type];

    if (!isJpgFormat) {
      hdrUrl = hdrUrl.replace('.jpg', '.hdr');
    }

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', hdrUrl + '?Content-Disposition=attachment');
    link.setAttribute('download', ``);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public onClose(): void {
    this.closeDialog.emit();
    this.colorSamplingService.alignment.config_json = undefined;
  }

  public async onSave(): Promise<void> {
    if (this.isSaveDisabled) return;
    this.colorSamplingService.pixels.sendPixel({
      event: 'click',
      button_name: 'color_test_save',
      color_test_session: this.colorSamplingService.session,
      color_test_session_instance: this.colorSamplingService.instanceSession,
      after_fix: this.colorSamplingService.fixed
    });
    let notificationType: NotificationType;
    let notificationText: string;
    let savedColorComparison: ArtistsJobsResourcesColorComparisonUI;

    try {
      savedColorComparison = await this.colorSamplingService.save();
      this.colorSamplingService.setInstanceUI(savedColorComparison);
      this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex]
        .artists_jobs_resources_color_comparisons.push(savedColorComparison);
      notificationText = 'Color sample test successfully saved!';
      notificationType = NotificationType.Success;
    } catch (err) {
      notificationText = err;
      notificationType = NotificationType.Error;
    } finally {
      this.checkIfColorTestSucceeded();
      const notification: Notification = {
        text: notificationText,
        type: notificationType,
        action: 'OK'
      };
      this.broadcaster.broadcast('notifyUser', notification);
    }
  }

  public toggleDistanceLimit(toggleState: boolean): void {
    this.assetAdjustmentsService.toggleNoDistanceLimit(toggleState);
  }

  public get defaultHdri(): string {
    return 'url(assets/preset-placeholder.svg)';
  }

  public get hexaPresetHdri(): string {
    return 'url(assets/hexa-preset.jpg)';
  }

  public get isSaveDisabled(): boolean {
    return (!this.colorSamplingService.alignment?.config_json?.data || this.colorSamplingService.processing) || (this.colorSamplingService.fixed && !this.colorSamplingService.saved);
  }

  private applyAlignmentPosition = async (): Promise<void> => {
    const alignment = await this.colorSamplingService.applyAlignmentPosition();
    if (alignment)
      this.setSelectedImage.emit(alignment.data_id);
  }

  private async initPresets(): Promise<void> {
    let allPresets: AdjustmentsPreset[];
    const execDefault = async () => {
      await this.colorSamplingService.applyDefaultScene();
      this._applyPosition$.next();
    };

    try {
      allPresets = await this.colorSamplingService.getPresets();
    } catch (err) {
      console.error(err);
      this._getPresetsFailedAttempts++
      if (this._getPresetsFailedAttempts < this._getPresetFailAttemptsThreshold) {
        return this.initPresets();
      }
      const notification: Notification = {
        text: 'An error occurred, please try again later',
        type: NotificationType.Error,
        action: 'OK',
        autoDismiss: false
      };
      return this.broadcaster.broadcast('notifyUser', notification);
    }

    if (!this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_color_comparisons) {
      this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_color_comparisons = [];
    }

    this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_color_comparisons.forEach(cc => {
      this.colorSamplingService.setInstanceUI(cc);
    });

    this.presets = allPresets.filter(p => p.retailer_sub_category_id)
      .sort((a: AdjustmentsPreset, b: AdjustmentsPreset) => a.sort_index - b.sort_index);

    if (this.presets.length) {
      await this.applyPreset(this.presets[0]);
    } else {
      this.presets = allPresets.filter(p => p.retailer_id)
        .sort((a: AdjustmentsPreset, b: AdjustmentsPreset) => a.sort_index - b.sort_index);

      if (this.presets.length) {
        await this.applyPreset(this.presets[0]);
      } else {
        await execDefault();
      }
    }

    const hdrsPrevs = this.enums.getHDRIPrev();
    this.presets.forEach(preset =>
      preset.hdriUrl = hdrsPrevs[preset.preset_json?.hdri?.type] ?`url(${hdrsPrevs[preset?.preset_json.hdri.type]})` : undefined);
    this.loaded.emit();
  }

  private onSpace(isDown: boolean): void {
    if (isDown) {
      if (!this._lastPixel) {
        this._lastPixel = this.colorSamplingService.pixelsTest;
      }
      this.colorSamplingService.pixelsTest = null;
    } else {
      this.colorSamplingService.pixelsTest = this._lastPixel;
      this._lastPixel = null;
    }
  }

  private checkIfColorTestSucceeded(): void {
    this.colorTestSucceeded = !!this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].
    artists_jobs_resources_color_comparisons?.find(colorComparison => colorComparison.isSuccess);
  }

  private blurActiveElement(): void {
    if (Reflect.has(document.activeElement, 'blur')) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  private onMessage(obj: PostMessage): void {
    if (obj && obj.action && obj.action === 'onAnimateEnterEnd') {
      this._applyPosition$.next();
    }
  }
}
