"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventObservableTypes = void 0;
var EventObservableTypes;
(function (EventObservableTypes) {
  EventObservableTypes[EventObservableTypes["ON_VIEWER_LOADED"] = 1] = "ON_VIEWER_LOADED";
  EventObservableTypes[EventObservableTypes["ON_GET_MESHES_DATA"] = 2] = "ON_GET_MESHES_DATA";
  EventObservableTypes[EventObservableTypes["ON_COLLISIONS"] = 3] = "ON_COLLISIONS";
  EventObservableTypes[EventObservableTypes["ON_ADJUDT_SCENE"] = 4] = "ON_ADJUDT_SCENE";
  EventObservableTypes[EventObservableTypes["ON_APPLY_PRESET"] = 5] = "ON_APPLY_PRESET";
  EventObservableTypes[EventObservableTypes["ON_SET_SCENE_SUMMARY"] = 6] = "ON_SET_SCENE_SUMMARY";
  EventObservableTypes[EventObservableTypes["ON_CREATE_IMAGES_BY_TOUR"] = 7] = "ON_CREATE_IMAGES_BY_TOUR";
  EventObservableTypes[EventObservableTypes["ON_LIGHTS_SUMMARY"] = 8] = "ON_LIGHTS_SUMMARY";
  EventObservableTypes[EventObservableTypes["ON_CONFIGURATOR_SELECT_DONE"] = 9] = "ON_CONFIGURATOR_SELECT_DONE";
  EventObservableTypes[EventObservableTypes["ON_MODEL_INTERACTION"] = 10] = "ON_MODEL_INTERACTION";
  EventObservableTypes[EventObservableTypes["ON_SET_VIEWER_FULLY_LOADED"] = 11] = "ON_SET_VIEWER_FULLY_LOADED";
  EventObservableTypes[EventObservableTypes["ON_SCREENSHOT"] = 12] = "ON_SCREENSHOT";
  EventObservableTypes[EventObservableTypes["ON_MODEL_LOADED"] = 13] = "ON_MODEL_LOADED";
  EventObservableTypes[EventObservableTypes["ON_EXPORT"] = 14] = "ON_EXPORT";
  EventObservableTypes[EventObservableTypes["ON_SCREENSHOTS_SEQUENCE"] = 15] = "ON_SCREENSHOTS_SEQUENCE";
  EventObservableTypes[EventObservableTypes["ON_ANIMATE_ENTER_END"] = 16] = "ON_ANIMATE_ENTER_END";
  EventObservableTypes[EventObservableTypes["ON_BOUNDING_BOX"] = 17] = "ON_BOUNDING_BOX";
  EventObservableTypes[EventObservableTypes["ON_GET_MESHE_ANIMATIONS"] = 18] = "ON_GET_MESHE_ANIMATIONS";
})(EventObservableTypes || (exports.EventObservableTypes = EventObservableTypes = {}));