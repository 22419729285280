import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArtistsItemSpecUI } from 'app/job/job';
import { ImageDialog } from 'app/offer/offers';
import { ImageDialogComponent } from 'app/shared/image-dialog/image-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from 'app/shared/utils.service';
import { KeyValuePair, ATTACHMENT_ICON } from 'app/shared/enums';
import { AttachmentFileType } from 'app/categories/category';
import { NotificationType } from 'app/shared/notifications';
import { RolesHelperService } from 'app/auth/roles-helper.service';

@Component({
    selector: 'app-specifications-slider',
    templateUrl: './specifications-slider.component.html',
    styleUrls: ['./specifications-slider.component.scss'],
    standalone: false
})
export class SpecificationsSliderComponent implements OnInit {
  @Input('specs') specs: Array<ArtistsItemSpecUI>;
  @Input('suppress-download') suppressDownload: boolean;
  @Input('suppress-download-msg') suppressDownloadMsg: string;
  @Output('on-index') onIndex: EventEmitter<ArtistsItemSpecUI>;
  public images: Array<KeyValuePair>;
  constructor(
    private dialog: MatDialog,
    private utils: UtilsService,
    public roles: RolesHelperService
  ) {
    this.onIndex = new EventEmitter<ArtistsItemSpecUI>();
  }

  ngOnInit() {
    this.images = [];
    if (this.specs) {
      this.specs.forEach(s => this.images.push({ key: s.title, value: s.isImage ? s.attachment : ATTACHMENT_ICON }));
    }
  }

  setIndex(index: number) {
    let spec = this.specs[index];
    if (!spec) return;
    if (spec.isImage) {
      const m = {
        title: 'Image Preview',
        url: spec.attachment
      } as ImageDialog;
      this.dialog.open(ImageDialogComponent, {
        data: m
      });
    }
    else {
      if (!this.roles.isSULogedin() && this.suppressDownload && spec.attachment_type == AttachmentFileType.SOURCE_3D_FILE)
        this.utils.notifyUser({ text: this.suppressDownloadMsg || 'unauthorized download', type: NotificationType.Info });
      else
        this.utils.multipleDownloads([spec.attachment], 'image_');
    }
    this.onIndex.next(spec);
  }

  specClick(spec: ArtistsItemSpecUI) {
    if (spec.isImage) {
      const m = {
        title: 'Image Preview',
        url: spec.attachment
      } as ImageDialog;
      this.dialog.open(ImageDialogComponent, {
        data: m
      });
    }
    else {
      if (!this.roles.isSULogedin() && this.suppressDownload && spec.attachment_type == AttachmentFileType.SOURCE_3D_FILE)
        this.utils.notifyUser({ text: this.suppressDownloadMsg || 'unauthorized download', type: NotificationType.Info });
      else {
        const download = () => {
          this.utils.multipleDownloads([spec.attachment], 'image_');
        };
        if (window.event?.target) {
          const e = window.event.target as HTMLElement;
          if (e.nodeName?.toLowerCase() === 'img' && e.getAttribute('alt') === 'specification file')
            download();
        }
        else
          download();
      }
    }
    this.onIndex.next(spec);
  }
}
