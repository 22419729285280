import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { BodyService } from 'app/shared/body.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-images-menu',
    templateUrl: './images-menu.component.html',
    styleUrls: ['./images-menu.component.scss'],
    standalone: false
})
export class ImagesMenuComponent {
  @Input('options-images') optionsImages: { [id: number]: string };
  @Input() value: number;
  @Input() placeholder: string;
  @Output('on-change') onSelectionChange: EventEmitter<number>;
  constructor(
    private utils: UtilsService,
    private body: BodyService
  ) {
    this.onSelectionChange = new EventEmitter<number>();
  }

  remove(e: MouseEvent) {
    this.utils.cancelEvent(e);
    this.onSelectMenuClick(null);
  }

  onSelectMenuClick(value: number) {
    this.value = value;
    this.onSelectionChange.next(this.value);
  }

  onMenuOpened() {
    // if (window.innerWidth < 550)
    //   document.body.classList.add('images-menu');
    // else
    //   document.body.classList.add('images-menu-big');
    setTimeout(() => {
      const img = document.body.querySelector(`.cdk-overlay-connected-position-bounding-box .mat-mdc-menu-item img[src="${this.optionsImages[this.value]}"]`);
      if (img) {
        const button = img.parentElement.parentElement;
        button.classList.add('active');
        this.body.scrollToElement(new ElementRef(button), 'center');
      }
    });
  }

  onMenuClosed() {
    // document.body.classList.remove('images-menu');
    // document.body.classList.remove('images-menu-big');
  }
}
