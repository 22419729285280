import { AfterViewInit, Component, EventEmitter, Input, Output, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ArtistsItemSpecUI } from 'app/job/job';
import { ATTACHMENT_ICON } from 'app/shared/enums';
import { PixelsService } from 'app/shared/pixels.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-specifications-instance',
    templateUrl: './specifications-instance.component.html',
    styleUrls: ['./specifications-instance.component.scss'],
    standalone: false
})
export class SpecificationsInstanceComponent implements AfterViewInit, OnDestroy {
  private hasDestroyed: boolean;
  @Input('spec') spec: ArtistsItemSpecUI;
  @Output('on-spec-click') onSpecClick: EventEmitter<ArtistsItemSpecUI>;
  @ViewChild('wrap') wrap: ElementRef;
  public ATTACHMENT_ICON = ATTACHMENT_ICON;
  constructor(
    private utils: UtilsService,
    private pixels: PixelsService
  ) {
    this.onSpecClick = new EventEmitter<ArtistsItemSpecUI>();
  }

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  async initIntersectionObserver() {
    await this.utils.onViewportVisibility(this.wrap, {
      once: true,
      threshold: 1.0
    });
    if (!this.hasDestroyed) {
      this.onSpecificationsIndex();
    }
  }

  onSpecificationsIndex() {
    this.pixels.sendPixel({
      event: 'guideline_view',
      reference_id: this.spec.referenceId,
      offer_id: this.spec.isOffer ? this.spec.referenceId : null,
      job_id: !this.spec.isOffer ? this.spec.referenceId : null,
      requirement_id: `${this.spec.source_table}_${this.spec.referenceId}`
    });
  }

  specClick() {
    this.onSpecClick.next(this.spec);
  }

  ngOnDestroy() {
    this.hasDestroyed = true;
  }
}
