import { Component, EventEmitter, forwardRef, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfferRequest, PolygonSpecifications, ArtistsOffersItem, PolyType, PolyShapeType, OfferRequestItem, ArtistsOffersGroup, ArtistsFixReasons, ItemPricing, Offer, OfferPrice } from '../offers';
import { JobTypes } from 'app/job-type/jot-type';
import { JobsTypesService } from 'app/job-type/jobs-types.service';
// import { ResourceTypesService } from 'app/product/resource-types.service';
// import { ResourceType, ProductsPricingkDataInner } from 'app/product/product';
import { UtilsService } from 'app/shared/utils.service';
import { Rank } from 'app/ui-components/rank';
import { User } from 'app/auth/user';
import { Group } from 'app/auth/group';
import { CategoryPrice, Product, ProductsPricingkDataInner, ResourceType } from 'app/product/product';
import { GraphqlService } from 'app/communication/graphql.service';
import { OfferService } from '../offer.service';
import { CALC_HOURS_TO_COMNPLETE, JobsTypes } from 'app/shared/enums';
import { IJobVariation, Job } from 'app/job/job';
import { ResourceTypesService } from 'app/product/resource-types.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationType, Notification } from 'app/shared/notifications';
import { AuthService } from 'app/auth/auth.service';
import { CategoriesService } from 'app/categories/categories.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { ResumableUploadService } from 'app/shared/resumable-upload.service';
import { FileWrap } from 'app/ui-components/file';
import { EndpointsService } from 'app/communication/endpoints.service';
import { FixReasonsService } from 'app/fix-reasons/fix-reasons.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ComplexityLevelDialogComponent } from 'app/product/complexity-level-dialog/complexity-level-dialog.component';
import { IGeometryComplexitySummery } from 'app/product/offer-creation-tool/offer-creation-tool.interface';
import { OfferCreationHelperService } from '../offer-creation-helper.service';
import { ValidateResponse } from 'app/shared/utils';

@Component({
    selector: 'app-offer-creation-fields',
    templateUrl: './offer-creation-fields.component.html',
    styleUrls: ['./offer-creation-fields.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OfferCreationFieldsComponent),
            multi: true
        }
    ],
    standalone: false
})
export class OfferCreationFieldsComponent implements OnChanges, ControlValueAccessor {
  public jobTypes: Array<JobTypes>;
  @Input('current-items') currentItems: Product[];
  @Input('current-item') currentItem: Product;
  @Input('selected-items') selectedItems: Product[];
  @Input('source-job') sourceJob: Job;
  @Input('is-multiple-offers') isMultipleOffers: boolean;
  @Input('product-resources') productResources: boolean;
  @Output('on-created') onCreated: EventEmitter<Array<Offer>> = new EventEmitter();
  public assign: FormGroup;
  public counter: number;
  public artists: { [id: number]: User };
  public manager: User;
  public feedbacker: User;
  public qualitySupervisor: User;
  public subCatPrices: Array<ProductsPricingkDataInner>;
  public polySpecs: Array<PolygonSpecifications>;
  @Input() product: Product;
  public qualitySv: number;
  public isSU: boolean;
  // private innerValue: OfferRequest;
  public allFormatsDictionary: { [id: number]: ResourceType };
  public averagePrice: Array<CategoryPrice>;
  public fileList: Array<FileWrap>;
  public uploading: boolean;
  public artistsFixReasons: Array<ArtistsFixReasons>;
  public artistsOffersGroup: Array<ArtistsOffersGroup>;
  public artistsOffersPricing: Array<ItemPricing>;
  public variations: OfferRequestItem[];
  public notesValue: Subject<string>;
  private geometryComplexitySummery: IGeometryComplexitySummery;
  public productsToAdd: Array<number>;
  constructor(
    public jobsTypesService: JobsTypesService,
    public offerService: OfferService,
    private utils: UtilsService,
    private gql: GraphqlService,
    private resourceTypesService: ResourceTypesService,
    private broadcaster: BroadcasterService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private categoriesService: CategoriesService,
    public rolesHelper: RolesHelperService,
    private resumableUploadService: ResumableUploadService,
    private endpoints: EndpointsService,
    private fixReasonsService: FixReasonsService,
    private dialog: MatDialog,
    public offerCreationHelper: OfferCreationHelperService
  ) {
    this.counter = 0;
    this.artists = {};
    this.productsToAdd = [];
    this.notesValue = new Subject<string>();
    // this._onGeometryComplexitySummery = [];

    this.isSU = this.rolesHelper.isSULogedin();
    this.assign = this.formBuilder.group({
      created_by: [this.auth.user.id, Validators.required],
      as_offer: [true],
      artist_user_id: [],
      notes: null,
      artists_offers_types: [[]],
      private_offer: [false],
      hours_to_complete: [OfferService.HOURS_TO_COMPLETE, Validators.required],
      base_price: [null, Validators.required],
      allow_rank_bonus: [null],
      allow_time_bonus: [null],
      complexity: [null, Validators.required],
      estimated_geometry_hours: [null, Validators.required],
      source_job: [null],
      category_id: [null],
      manager_id: [null],
      resource_id: [null],
      multiple_offers: [null],
      same_mesh: [null],
      is_poc: [false],
      is_test: [false],
      hidden: [false],
      artists_offers_groups: [null],
      artists_offers_pricing: [null],
      priority: [null],
      quality_supervisor: [null],
      fix: [false],
      fix_reason: [null],
      mini_job: [false],
      auto_incremented_rank: [true],
      automatic_rendering: [false],
      first_variation_price: [null],
      additional_variation_price: [null]
    });

    this.assign.controls.priority.disable();

    this.polySpecs = [];
    this.fileList = [];
    this.resourceTypesService.getResourceTypes().subscribe(
      (res: Array<ResourceType>) => {
        this.allFormatsDictionary = {};
        res.forEach(r => {
          this.allFormatsDictionary[r.id] = r;
        });
      }
    );
    this.artistsOffersGroup = [];
    this.artistsOffersPricing = [];
  }


  async ngOnInit() {
    this.assign.get('multiple_offers').setValue(this.isMultipleOffers ?? false);
    this.jobTypes = await this.jobsTypesService.getCachedTypes();
    if (this.sourceJob) {
      this.assign.controls.source_job.setValue(this.sourceJob.id);
      this.assign.controls.category_id.setValue(this.sourceJob.artists_jobs_items[0].artists_items[0].category_id);
    }

    this.setDefaultPoly();
    this.refreshSubCatPrice();
    this.getAveragePrice();
    this.qualitySv = this.rolesHelper.getRoleIdbyName('Quality Supervisor');

    this.artistsFixReasons = await this.fixReasonsService.getCachedFixReasons();
    if (this.product?.priority) {
      this.assign.controls.priority.setValue(this.product.priority);
    }
  }

  getNumOfItems() {
    let num = 1;
    if (this.currentItems)
      num = this.currentItems.length;
    else if (this.variations?.length)
      num = this.variations.length;
    else if (this.sourceJob)
      num = Math.max(this.sourceJob?.artists_jobs_items.length, this.productsToAdd.length);
    return num;
  }

  setAdditionalVariationPriceState() {
    if (this.getNumOfItems() < 2) {
      this.assign.controls.additional_variation_price.disable();
    } else {
      this.assign.controls.additional_variation_price.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if ((!this.currentItems || this.currentItems.length < 2) && (!this.sourceJob?.artists_jobs_items.length || this.sourceJob?.artists_jobs_items.length == 1))
    this.setAdditionalVariationPriceState();
    if (changes.currentItems && !changes.currentItems.firstChange) {
      this.refreshSubCatPrice();
      this.updateComplexity();
    }
  }

  updateComplexity() {
    let geometryComplexity = -1;
    this.currentItems.forEach(p => {
      if (p.estimated_geometry_hours)
        geometryComplexity = Math.max(geometryComplexity, p.estimated_geometry_hours);
    });
    if (geometryComplexity > -1)
      this.assign.controls.estimated_geometry_hours.setValue(geometryComplexity);
  }

  onFixReasonsValueChange(event) {
    this.assign.controls.fix_reason.setValue(event?.value);
  }

  getAveragePrice() {
    if (this.product && this.product.category_id) {
      this.categoriesService.getCategoryPricing(this.product.category_id).subscribe(
        res => {
          this.averagePrice = res
        }
      )
    }
  }

  /**
   * updating selected variations in job object
   * @param selectedItems
   */
  public async onItemVariationChange(selectedItems: IJobVariation[]): Promise<void> {
    this.variations = [];
    selectedItems.forEach((item) => {
      let obj = {
        'id': item.id,
        'downloads': []
      } as OfferRequestItem;
      obj[this.getResourceField()] = item.resources;
      this.variations.push(obj);
    });
    if (this.sourceJob?.artists_jobs_items[0].artists_items[0].products[0].products_sub_categories_associations.length)
      await this.setDefaultPrices(this.sourceJob.artists_jobs_items[0].artists_items[0].products[0].products_sub_categories_associations[0].sub_category_id);
    this.onVarPriceChange();
  }

  getResourceField() {
    return this.productResources ? 'resources' : 'artists_jobs_resources';
  }

  setDefaultPoly() {
    if (this.sourceJob) {
      this.polySpecs = this.utils.deepCopyByValue(this.sourceJob.artists_jobs_items[0].artists_jobs_items_polygon_specifications);
    } else if (this.product) {
      this.polySpecs = this.utils.deepCopyByValue(this.product.products_polygon_specifications);
    } else if(this.currentItems.length){ // multiple offer will show poly variation of one of products
      this.polySpecs = this.utils.deepCopyByValue(this.currentItems[0].products_polygon_specifications);
    }
  }

  writeValue(value: OfferRequest): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }

  onTextChange(textModels: string) {
    if (this.utils.getTextContent(textModels) || (textModels && textModels.indexOf('<img ') > -1)) {
      this.assign.controls.notes.setValue(textModels);
    } else {
      this.assign.controls.notes.setValue(null);
    }
  }

  onRankChange(rank: Rank) {
    this.assign.controls.complexity.setValue((rank && rank.value) ? rank.value : 0);
  }

  digestArtists() {
    if (Object.keys(this.artists).length) {
      this.assign.controls.artist_user_id.setValue(Object.keys(this.artists).map(i => parseInt(i)));
    } else {
      this.assign.controls.artist_user_id.setValue([]);
    }
  }

  removeArtist(artist) {
    const artistscopy = this.utils.deepCopyByValue(this.artists);
    delete artistscopy[artist.value.id];
    this.artists = artistscopy;
    this.digestArtists();
  }

  onArtistChange(artist: User) {
    if (artist) {
      this.artists[artist.id] = artist;
      this.digestArtists();
      this.counter++;
    }
  }

  async openGeometryComplexity() {
    let product = this.getProduct();
    if (!product && this.sourceJob)
      product = (await this.utils.observableToPromise(this.gql.product(this.sourceJob.artists_jobs_items[0].artists_items[0].product_id))).data.products;
    if (!product) {
      this.utils.notifyUser({
        text: 'First select a product',
        type: NotificationType.Error
      } as Notification);
      return;
    }
    if (!product.products_sub_categories_associations[0]) {
      this.utils.notifyUser({
        text: 'First select a sub category',
        type: NotificationType.Error
      } as Notification);
      return;
    }
    // if (!product.products_sub_categories_associations[0]) {
    //   await this.categoriesService.getFullCategoriesAsync();
    //   this.categoriesService.
    // }
    const dialogRef = this.dialog.open(ComplexityLevelDialogComponent, {
      // width: 'calc(100vw - 20px)',
      height: 'inherit',
      data: {
        productId: product.id,
        subCategoryId: product.products_sub_categories_associations[0].sub_category_id
      }
    });
    dialogRef.afterClosed().subscribe((e: IGeometryComplexitySummery) => {
      if (!e) return;
      this.geometryComplexitySummery = e;
      this.assign.controls.estimated_geometry_hours.setValue(e.price);
      const product = this.getProduct();
      product.estimated_geometry_hours = e.price;
      this.offerService.saveProduct(product);
      this.onOfferTypesChange();
    });
  }

  getArtistsCount(): number {
    return Object.keys(this.artists).length;
  }

  onGroupChange(group: Group) {
    group.artists_groups_users.forEach(agu => {
      agu.artists_users.forEach(u => this.onArtistChange(u as any));
    });
  }

  onPersistentGroupChange(group: Group, i: number) {
    if (group != null) {
      this.artistsOffersGroup[i].group_id = group.id;


      this.assign.controls.artists_offers_groups.setValue(this.artistsOffersGroup);
    }
    this.counter++;
  }

  onGroupPrice(event, aog: ItemPricing) {
    const aop = this.artistsOffersPricing.filter(a => a.group_id === aog.group_id);
    if (aop && aop.length > 0) {
      aop[0].base_price = 1 * event.target.value;
    } else {
      const artists_offers_pricing = {
        base_price: 1 * event.target.value,
        rank_bonus: 0,
        time_bonus: 0,
        group_id: aog.group_id
      };
      this.artistsOffersPricing.push(artists_offers_pricing);
    }
    this.assign.controls.artists_offers_pricing.setValue(this.artistsOffersPricing);
  }

  addPersistentGroup() {
    this.artistsOffersGroup.push({ group_id: null })
  }

  deletePersistentGroup(i: number) {
    if (i !== null) {
      const groupToDelete = this.artistsOffersGroup[i].group_id;
      this.artistsOffersGroup.splice(i, 1);
      this.assign.controls.artists_offers_groups.setValue(this.artistsOffersGroup);
      this.artistsOffersPricing = this.artistsOffersPricing.filter(a => a.group_id !== groupToDelete);
      this.assign.controls.artists_offers_pricing.setValue(this.artistsOffersPricing);
    }
  }

  onManagerChange(manager: User) {
    if (manager) {
      this.manager = manager;
      this.assign.controls.manager_id.setValue(this.manager.id);
    }
  }

  onFeedbackerChange(manager: User) {
    if (manager) {
      this.feedbacker = manager;
      this.assign.controls.feedbacker_id.setValue(this.manager.id);
    }
  }

  clearFeedbacker() {
    delete this.manager;
    this.assign.controls.manager_id.setValue(null);
  }

  onqualitySupervisorChange(qualitySupervisor: User) {
    if (qualitySupervisor) {
      this.qualitySupervisor = qualitySupervisor;
      this.assign.controls.quality_supervisor.setValue(this.qualitySupervisor.id);
    }
  }

  clearManager() {
    delete this.manager;
    this.assign.controls.manager_id.setValue(null);
  }

  clearqualitySupervisor() {
    delete this.qualitySupervisor;
    this.assign.controls.quality_supervisor.setValue(null);
  }

  private async setDefaultPrices(subCat: number) {
    const price = await this.offerCreationHelper.getDefaultPrices(subCat, this.getNumOfItems(), this.geometryComplexitySummery?.price, this.isMultipleOffers, this.assign.controls.same_mesh.value);
    if (price.additional_variation_price)
      this.assign.controls.additional_variation_price.setValue(price.additional_variation_price);
    if (price.first_variation_price)
      this.assign.controls.first_variation_price.setValue(price.first_variation_price);
    if (price.base_price)
      this.assign.controls.base_price.setValue(price.base_price);
    if (price.additional_variation_price || price.first_variation_price)
      this.onVarPriceChange();
    if (CALC_HOURS_TO_COMNPLETE)
      this.assign.controls.hours_to_complete.setValue(price.hours_to_complete);
  }

  private async refreshProductsPricing(pid: number) {
    const res = await this.utils.observableToPromise(this.gql.productsPricing(pid));
    this.subCatPrices = res.data.products_pricing;
    // this.gql.productsPricing(pid).subscribe(res => this.subCatPrices = res.data.products_pricing);
  }

  private getProduct() {
    return this.product || (this.currentItems && this.currentItems[0]) || this.sourceJob?.artists_jobs_items[0].artists_items[0].products[0];
  }

  async refreshSubCatPrice() {
    if (this.sourceJob) {
      this.refreshProductsPricing(this.sourceJob.artists_jobs_items[0].artists_items[0].product_id);
      if (this.sourceJob.artists_jobs_items[0].artists_items[0].products[0].products_sub_categories_associations.length) {
        await this.setDefaultPrices(this.sourceJob.artists_jobs_items[0].artists_items[0].products[0].products_sub_categories_associations[0].sub_category_id);
      }
    }
    const product = this.getProduct();
    if (product) {
      this.refreshProductsPricing(product.id);
      if (product.products_sub_categories_associations && product.products_sub_categories_associations[0]) {
        await this.setDefaultPrices(product.products_sub_categories_associations[0].sub_category_id);
      }
    }
  }

  public removePoly(index: number): void {
    this.polySpecs.splice(index, 1);
  }

  public addPoly(): void {
    this.polySpecs.push({
      poly_type: PolyType.MID,
      poly_shape_type: PolyShapeType.TRIANGULAR
    } as PolygonSpecifications)
  }

  public duplicatePolySpec(polySpec: PolygonSpecifications): void {
    this.polySpecs.push({ ...polySpec });
  }

  async onOfferTypesChange() {
    this.offerCreationHelper.isTextureOnly = !!(this.assign.controls.artists_offers_types.value.length === 1 && this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.TEXTURE).length);
    this.offerCreationHelper.isGeometryOnly = !!(this.assign.controls.artists_offers_types.value.length === 1 && this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.GEOMETRY).length);
    this.offerCreationHelper.isRenderOnly = !!(this.assign.controls.artists_offers_types.value.length === 1 && this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.RENDR).length);
    this.offerCreationHelper.isTexture = !!this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.TEXTURE).length;
    this.offerCreationHelper.isGeometry = !!this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.GEOMETRY).length;
    this.offerCreationHelper.isRender = !!this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.RENDR).length;

    if (this.offerCreationHelper.isTextureOnly) {
      this.assign.controls.base_price.disable();
    } else {
      this.assign.controls.base_price.enable();
    }
    this.onVarPriceChange();
    // if (!this.isTextureOnly) {
    await this.refreshSubCatPrice();
    // }
    this.attachFeedback();
  }

  isJobHasType(job: Job, type: JobsTypes) {
    return !!(job.artists_jobs_types.filter(i => i.type_id == type).length);
  }

  attachFeedback() {
    if (this.sourceJob) {
      let hasSameType = false;
      this.assign.controls.artists_offers_types.value.forEach((i: JobsTypes) => {
        if (this.isJobHasType(this.sourceJob, i))
          hasSameType = true;
      });
      let textEditorText = '';
      if (hasSameType) {
        const feedbacks = this.offerService.getFeedbacks(this.sourceJob);
        if (feedbacks.length) {
          feedbacks.forEach(f => {
            if (f.screenshot)
              textEditorText += `<div><img style="border: solid 1px #E2E2E2;background-color: #fff;border-radius: 4px;" src="${f.screenshot}" /></div>`
            textEditorText += f.notes;
          });
        }
      }
      if (textEditorText)
        textEditorText = `<h3 style="text-decoration: underline;">Please fix feedbacks:</h3>${textEditorText}`;
      this.notesValue.next(textEditorText);
    }
  }

  isSameMeshDisabled(): boolean {
    return !this.assign.controls.multiple_offers.value && this.assign.controls.artists_offers_types.value.filter((i: number) => i == JobsTypes.GEOMETRY).length != 0;
  }

  isAutomaticRendering() {
    return this.assign.controls.artists_offers_types.value.some((i: number) => i == JobsTypes.RENDR);
  }

  onChangeMultipleOffers(e) {
    if (e.checked == true) {
      this.isMultipleOffers = true;
    } else {
      this.isMultipleOffers = false;
    }
    this.refreshSubCatPrice();
  }

  onVarPriceChange() {
    if (this.offerCreationHelper.isTextureOnly) {
      // this.assign.controls.base_price.setValue(this.assign.controls.first_variation_price.value + ((this.getNumOfItems() - 1) * (this.assign.controls.additional_variation_price.value || 0)));
      this.assign.controls.base_price.setValue(this.offerCreationHelper.getBasePriceForTextureOnly(this.assign.controls.first_variation_price.value, this.assign.controls.additional_variation_price.value, this.getNumOfItems(), this.isMultipleOffers));
    }
  }

  async createOffer() {
    if (this.assign.controls.private_offer.value) {
      this.assign.controls.artist_user_id.setValue(Object.keys(this.artists).map(i => parseInt(i)));
    }
    // if (this.isTextureOnly)
    //   this.assign.controls.base_price.setValue(this.assign.controls.first_variation_price.value + ((this.getNumOfItems() - 1) * (this.assign.controls.additional_variation_price.value || 0)));
    const request = this.assign.getRawValue() as OfferRequest;
    request.multiple_offers = this.isMultipleOffers;
    request.artists_offers_types = this.offerService.getArtistsOffersTypes(this.assign.controls.artists_offers_types.value);
    request.artists_offers_pricing = [];
    if (this.assign.controls?.artists_offers_pricing?.value) {
      request.artists_offers_pricing = this.assign.controls.artists_offers_pricing.value;
    }

    const nullableGroup = request.artists_offers_pricing.filter(a => a.group_id === null);
    if (nullableGroup && nullableGroup.length > 0) {
      nullableGroup[0].base_price = this.assign.controls.base_price.value;
    } else {
      request.artists_offers_pricing.push({
        base_price: this.assign.controls.base_price.value,
        rank_bonus: 0,
        time_bonus: 0,
        group_id: null
      })
    }

    if (this.manager?.id) {
      request.manager_id = this.manager.id;
    }
    if (this.polySpecs && this.polySpecs.length) {

      if (this.polySpecs.some(
        p =>
          !p.poly_shape_type ||
          !p.poly_type ||
          !p.job_type ||
          !p.min_poly_count ||
          !p.max_poly_count)) {
        alert('You must fill mandatory fields in poly specs');
        return;
      }
      else {
        this.polySpecs.map(i => delete i.id);
        request.artists_offers_items = [
          {
            artists_offers_items_polygon_specifications: this.polySpecs
          } as ArtistsOffersItem
        ]
      }
    }

    // similar items page
    if (this.currentItem?.category_id) {
      request.category_id = this.currentItem.category_id;
    }

    let resources = [];

    if (this.selectedItems) {
      this.selectedItems.forEach(p => {
        resources = resources.concat(p.products_resources);
      });
    }

    // multiple selection
    if (this.currentItems && this.currentItems[0]?.category_id) {
      request.category_id = this.currentItems[0].category_id;
      // request.multiple_offers = true;
    }

    this.setRequestItems(request);

    if (this.product) {
      this.currentItems = [this.product];
      request.category_id = this.product.category_id;
    }

    if (this.currentItem && this.currentItems?.length === 0) {
      this.currentItems = [this.currentItem];
    }

    if (this.currentItems) {
      for (let i = 0; i < this.currentItems.length; i++) {
        let obj = {
          id: this.currentItems[i].id,
          downloads: await this.uploadFiles()
        } as OfferRequestItem;
        obj[this.getResourceField()] = resources;
        request.items.push(obj);
      }
    }
    if (this.sourceJob && this.productsToAdd.length) {
      this.sourceJob.artists_jobs_items.forEach(aji => {
        resources = resources.concat(aji.artists_jobs_resources.filter(r => r.approved === 1));
      });
    }
    this.productsToAdd.forEach(id => {
      let obj = {
        id,
        downloads: []
      } as OfferRequestItem;
      obj[this.getResourceField()] = resources;
      request.items.push(obj);
    });
    let source_job: number;
    if (this.productsToAdd.length) {
      source_job = request.source_job;
      delete request.source_job;
    }
    if (this.sourceJob && !this.currentItems || this.currentItems?.length == 0) {
      request.downloads = await this.uploadFiles();
    }

    let validation: ValidateResponse;
    if (this.currentItems?.length > 0) {
      for (let i = 0; i < this.currentItems.length; i++) {
        if (request.multiple_offers) {
          this.selectedItems = this.currentItems;
        }
        const wrap = { request: request, currentItem: this.currentItems[i], selectedItems: this.selectedItems };
        await this.offerService.syncGeometryComplexity(request, wrap);
        validation = this.offerService.validateOfferRequest(wrap);
      }
    } else {
      const wrap = { request };
      await this.offerService.syncGeometryComplexity(request, wrap);
      validation = this.offerService.validateOfferRequest(wrap);
    }
    if (!validation.isValid) {
      const data: Notification = {
        text: validation.reason,
        type: NotificationType.Error,
        action: 'OK'
      };
      this.broadcaster.broadcast('notifyUser', data);
      return;
    }

    if (this.feedbacker) {
      request.is_sticky = true;
      request.feedbacker_id = this.feedbacker.id;
    } else {
      // request.is_sticky = Boolean(Math.floor(Math.random() * 2)); // A/B testing on offer creation
      request.is_sticky = false;
      request.feedbacker_id = null;
    }

    let subCat: number;
    const product = this.getProduct();
    if (product) {
      request.estimated_geometry_hours = product.estimated_geometry_hours;
      if (product.products_sub_categories_associations && product.products_sub_categories_associations[0])
        subCat = product.products_sub_categories_associations[0].sub_category_id;
    }
    this.offerService.createOffer(request, (offers: Array<Offer>) => {
      if (source_job)
        request.source_job = source_job;
      this.onCreated.next(offers);
    }, await this.offerCreationHelper.getDefaultPrices(subCat, this.getNumOfItems(), this.geometryComplexitySummery?.price, this.isMultipleOffers, this.assign.controls.same_mesh.value));
  }

  /**
   * Setting variations or empty array if no variations or previous array value exists
   * @param request
   */
  private setRequestItems(request: OfferRequest): void {
    if (this.variations && this.variations.length > 0) {
      request.items = this.variations;
      delete request.source_job;
    } else {
      if (!request.items) {
        request.items = [];
      }
    }
    this.onVarPriceChange();
  }

  private async uploadFiles(): Promise<Array<string>> {
    const urls = [];
    this.uploading = true;
    const bucket = this.endpoints.getEndpointDomain('cdn').replace('https://', '');
    for (const file of this.fileList) {
      urls.push(await this.resumableUploadService.file(file.file, bucket));
    }
    // let urls = await Promise.all(promises);
    this.uploading = false;
    return urls;
  }

  async onAdditionalFilesChange(fileList: Array<FileWrap>) {
    this.fileList = this.fileList.concat(fileList);
  }

  removeFile(index: number) {
    this.fileList.splice(index, 1);
  }

  onProductsChange(val: string) {
    if (!val) {
      return;
    }

    const num = parseInt(val);
    if (!isNaN(num)) {
      this.productsToAdd.push(num);
    }
    this.setAdditionalVariationPriceState();
  }

  public removeProduct(index: number): void {
    this.productsToAdd.splice(index, 1);
    this.setAdditionalVariationPriceState();
  }
}
