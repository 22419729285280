/* eslint-disable max-len */
import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { JobService } from '../job.service';
import { RolesHelperService } from 'app/auth/roles-helper.service';
import { AuthService } from 'app/auth/auth.service';
import { NativeResourceSet } from 'app/offer/offers';
import { AdjustmentsMode, JobsTypes, KeyValuePair } from 'app/shared/enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdjustmentsData } from 'app/ui-components/asset-adjustments';
import { AssetAdjustmentsHelperService } from 'app/ui-components/asset-adjustments-helper.service';
import {
  ALIGNMENT_ORIGIN,
  ArtistsJobsResourcesAlignment,
  CombineImagesMode,
  CombineImagesResponse,
  CreatorsCameraControlsState,
  MediaTag,
  Resource,
  ResourceMode,
  ResourceWarningType
} from '../resource';
import { AssetAdjustmentSector, AssetAdjustmentsService, CameraControlsState } from 'asset-adjustments';
import { UtilsService } from 'app/shared/utils.service';
import { RestService } from 'app/communication/rest.service';
import { Notification, NotificationType } from 'app/shared/notifications';
import { DecimalPipe } from '@angular/common';
import { PixelsService } from 'app/shared/pixels.service';
import { EnumsService } from 'app/shared/enums.service';
import { CollisionService } from 'app/ui-components/collision.service';
import { map } from 'rxjs/operators';
import { ColorSamplingService } from 'app/ui-components/color-sampling.service';
import { ResourcePolygonReductionService } from '../resource-polygon-reduction.service';
import { ResourcePolygonReduction } from '../resource-polygon-reduction';
import { IButtonToggle } from '../../ui-components/button-toggle-group/button-toggle-group.model';
import { TutorialOptions, TutorialStage } from '../../tutorial/tutorial';
import { TutorialService } from '../../tutorial/tutorial.service';

@Component({
    selector: 'app-asset-adjustments-wrap',
    templateUrl: './asset-adjustments-wrap.component.html',
    styleUrls: ['./asset-adjustments-wrap.component.scss'],
    standalone: false
})
export class AssetAdjustmentsWrapComponent implements OnInit, AfterContentInit {
  public iframeModel: ElementRef;
  public images: Array<NativeResourceSet>;
  public sliderImages: Array<KeyValuePair>;
  public currentImageIndex: number;
  public adjustments: boolean;
  public mediaTag: MediaTag;
  public feedbackMode: CombineImagesMode;
  public resourceWarningType: ResourceWarningType;
  public generateScreenshot: number;
  public alignment: ArtistsJobsResourcesAlignment;
  public jumpToTab: AssetAdjustmentSector;
  public currentMode: ResourceMode;
  public allResourceModes: Array<KeyValuePair> = [];
  public resourceModesIcons: { [id: number]: string };
  public resourceModesActive: { [id: number]: boolean };
  public combineImagesModeToggles: IButtonToggle<CombineImagesMode>[] = [
    { value: CombineImagesMode.SIDE_BY_SIDE, label: 'SIDE BY SIDE' },
    { value: CombineImagesMode.MODEL_ON_TOP, label: 'MODEL ON TOP' },
    { value: CombineImagesMode.MODEL_ONLY, label: 'MODEL ONLY' }
  ];
  public hide3DassetsDropdown: boolean;
  public showLoader: boolean;
  public isSU: boolean;
  public show3dAssetsDropdown: boolean;
  readonly resourceMode = ResourceMode;
  private alignmentPos: CreatorsCameraControlsState;
  private _alignmentProcess: boolean;
  private _currentOrigin: ALIGNMENT_ORIGIN;

  constructor(
    @Inject(MAT_DIALOG_DATA) public adjustmentsData: AdjustmentsData,
    private dialogRef: MatDialogRef<AssetAdjustmentsWrapComponent>,
    public jobService: JobService,
    public rolesHelper: RolesHelperService,
    public auth: AuthService,
    private cdr: ChangeDetectorRef,
    public aHelperService: AssetAdjustmentsHelperService,
    private utils: UtilsService,
    private rest: RestService,
    public assetAdjustmentsService: AssetAdjustmentsService,
    private decimalPipe: DecimalPipe,
    private pixels: PixelsService,
    private enums: EnumsService,
    public collisionService: CollisionService,
    public colorSamplingService: ColorSamplingService,
    private resourcePolygonReductionService: ResourcePolygonReductionService,
    private tutorialService: TutorialService
  ) {
    this.adjustments = false;
    this.generateScreenshot = 0;
    this.mediaTag = MediaTag.MODEL;
    this.feedbackMode = adjustmentsData.combineImagesMode;
    this.resourceWarningType = adjustmentsData.resourceWarningType;
    if (this.feedbackMode === CombineImagesMode.MODEL_ON_TOP) {
      this.aHelperService.modelOpacity = 0.5;
    } else {
      this.aHelperService.modelOpacity = 1;
    }
    this.images = this.jobService.getJobImagesById(this.jobService.currentArtistsjobitemId);
    this.mapSliderImages();
    this.currentImageIndex = 0;
    this.currentMode = ResourceMode.ADJUSTMENTS;
    if (this.canGetReuseAlignments(this.jobService.job)) {
      this.getReuseAlignments();
    }
    this.initResourceModesDictionaries();
    if (this.adjustmentsData.resourceWarningType === ResourceWarningType.MissingColorComparison) {
      this.hide3DassetsDropdown = true;
      this.showLoader = true;
    }
    this.isSU = rolesHelper.isSULogedin();
  }

  ngOnInit() {
    this.setResourceModes();
    this.updateCollision();
    this.checkIfShouldShow3dAssetsDropdown();
  }

  ngAfterContentInit() {
    switch (this.resourceWarningType) {
      case ResourceWarningType.MissingGeometryAccuracy: {
        this.changeMode(ResourceMode.ALIGNMENT);
        break;
      }
      case ResourceWarningType.MissingColorComparison: {
        this.changeMode(ResourceMode.COLOR_SAMPLING);
        break;
      }
    }
    setTimeout(() => {
      if (document.activeElement.nodeType === Node.ELEMENT_NODE) {
        (document.activeElement as HTMLElement).blur();
      }
    }, 1000)
  }

  get CombineImagesMode(): typeof CombineImagesMode{
    return CombineImagesMode
  }

  get isAlignmentMode(): boolean {
    return this.currentMode === ResourceMode.ALIGNMENT;
  }

  get isColorSampleMode(): boolean {
    return this.currentMode === ResourceMode.COLOR_SAMPLING;
  }

  get alignmentProcess(): boolean {
    return this._alignmentProcess;
  }

  set alignmentProcess(value: boolean) {
    this._alignmentProcess = value;
  }

  public onColorSamplingLoaded(): void {
    this.showLoader = false;
  }

  private initResourceModesDictionaries(): void {
    this.resourceModesIcons = {};
    this.resourceModesIcons[ResourceMode.ADJUSTMENTS] = 'settings';
    this.resourceModesIcons[ResourceMode.ALIGNMENT] = 'format_align_center';
    this.resourceModesIcons[ResourceMode.COLOR_SAMPLING] = 'colorize';
    this.resourceModesIcons[ResourceMode.DECIMATE_POLYGONS] = 'polyline';
    this.resourceModesActive = {};
    this.resourceModesActive[ResourceMode.ADJUSTMENTS] = true;
    this.resourceModesActive[ResourceMode.ALIGNMENT] = this.jobService.job.UI.isGeometry || this.rolesHelper.isAdminLogedin();
    this.resourceModesActive[ResourceMode.COLOR_SAMPLING] = this.jobService.job.UI.isTexture || this.rolesHelper.isAdminLogedin();
    // TODO implement app-resource-polygon-reduction component:
    this.resourceModesActive[ResourceMode.DECIMATE_POLYGONS] = this.jobService.job.UI.isTexture || this.rolesHelper.isGodLogedin();
    // For now it's always false:
    // this.resourceModesActive[ResourceMode.DECIMATE_POLYGONS] = false;
  }

  private setResourceModes(): void {
    const resourceModes: Set<KeyValuePair> = new Set();
    const adjustments = {
      key: ResourceMode.ADJUSTMENTS,
      value: 'adjustments'
    };
    const geometryAccuracy ={
      key: ResourceMode.ALIGNMENT,
      value: 'geometry accuracy'
    };
    const colorSampling = {
      key: ResourceMode.COLOR_SAMPLING,
      value: 'color sampling'
    };
    const reducePolygons = {
      key: ResourceMode.DECIMATE_POLYGONS,
      value: 'decimate polygons'
    };

    this.jobService.job.artists_jobs_types.forEach(type => {
      switch (type.type_id) {
        case JobsTypes.GEOMETRY: {
          resourceModes.add(adjustments);
          resourceModes.add(geometryAccuracy);
          break;
        }
        case JobsTypes.TEXTURE: {
          resourceModes.add(adjustments);
          resourceModes.add(colorSampling);
          resourceModes.add(reducePolygons);
          if (this.isSU)
            resourceModes.add(geometryAccuracy);
          break;
        }
        case JobsTypes.FIX: {
          break;
        }
        case JobsTypes.RENDR: {
          break;
        }
      }
    })
    this.allResourceModes = [...resourceModes];
  }

  private canGetReuseAlignments(job): boolean {
    const jobUI = job.UI;
    return ((jobUI.isGeometry && !job.same_mesh) || jobUI.isTexture) && !jobUI.isRender;
  }

  private getReuseAlignments(): void {
    this.jobService.resourcesAlignments(this.jobService.job.id).pipe(map(res => res.data.resourcesAlignments?.rows)).subscribe((result: Array<Resource>) => {
      if (!result) {
        return;
      }

      const currentItem = this.jobService.currentArtistsjobitem;
      const jobItemsIds = this.jobService.job.artists_jobs_items.map(jobItem => jobItem.id);
      currentItem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments = currentItem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments.filter(el => !el.reuse_alignment)
      const currentJobRes = currentItem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments;
      const currentJobAlignments = [...new Set(result.filter(res => jobItemsIds.includes(res.job_item_id) && res.artists_jobs_resources_alignments.length))];
      const otherJobAlignments = result.filter(res => !jobItemsIds.includes(res.job_item_id) && res.artists_jobs_resources_alignments.length && res.type_id === 1);

      currentJobAlignments?.forEach(el => {

        if (el.job_item_id === this.jobService.currentArtistsjobitem.id){
          return;
        }

        let alignment: ArtistsJobsResourcesAlignment;

        if (el.artists_jobs_resources_alignments.length > 1) {
          alignment = el.artists_jobs_resources_alignments.reduce((a, b) => a.created_at > b.created_at ? a : b);
        } else {
          alignment = el.artists_jobs_resources_alignments[0];
        }

        const jobItem = this.jobService.job.artists_jobs_items.filter(e => e.id === el.job_item_id)[0];
        const small_img = jobItem.artists_items[0].artists_items_data[0].small_image_url;
        alignment = { ...alignment, reuse_alignment: { small_img } };
        currentJobRes.push({ ...alignment });
      });

      if (otherJobAlignments.length && this.jobService.job.UI.isTexture && !this.jobService.job.UI.isGeometry) {
        let geoJobAlignment = otherJobAlignments.map(el => el.artists_jobs_resources_alignments).flat().reduce((a, b) => a.created_at > b.created_at ? a : b);
        currentJobRes.push({ ...geoJobAlignment, reuse_alignment: {} });
      }

      currentJobRes.forEach(r => {
        r.rolesUI = this.rolesHelper.roleAbbreviation(r.artists_users[0].artists_users_roles);
      })
    });
  }

  private mapSliderImages(): void {
    this.sliderImages = [];
    this.images.forEach(i => {
      this.sliderImages.push({
        key: null, // i.id,
        value: i.small
      })
    });
  }

  public setAdjustments(iframe: ElementRef): void {
    this.iframeModel = iframe;
    this.adjustments =  this.iframeModel &&
                        this.iframeModel.nativeElement &&
                        this.iframeModel.nativeElement.parentElement &&
                        this.jobService.adjustmentsMode === AdjustmentsMode.FULLSCREEN;
  }

  public setIndex(index: number): void {
    this.currentImageIndex = index;
    this.updateCollision();
  }

  public setCurrentItemById(id: number): void {
    if (this.jobService.currentArtistsjobitemId === id) {
      return;
    }

    delete this.aHelperService.currentPreset;
    this.jobService.setCurrentItemById(id);
    this.images = this.jobService.getJobImagesById(this.jobService.currentArtistsjobitemId);
    this.mapSliderImages();
    if (this.canGetReuseAlignments(this.jobService.job)) {
      this.getReuseAlignments();
    }
    this.cdr.detectChanges();
  }

  public setPosition(pos: CreatorsCameraControlsState): void {
    this.alignmentPos = pos;
    this.afterPositionAndScreenshot();
  }

  public onScreenshot(res: CombineImagesResponse): void {
    if (this.alignmentPos) {
      this.alignmentPos.data = res;
    }
    this.afterPositionAndScreenshot();
  }

  public setAlignmentOrigin(o: ALIGNMENT_ORIGIN) {
    this._currentOrigin = o;
  }

  private async afterPositionAndScreenshot(): Promise<void> {
    if (!this.alignmentProcess && this.alignmentPos && this.alignmentPos.data?.screenshot) {
      this.assetAdjustmentsService.attachMessages();
      this.alignmentProcess = true;
      this.alignmentPos.data.screenshot = await this.utils.getUrlFromBase64(this.alignmentPos.data.screenshot);
      // await this.ruService.file(this.alignmentScreenshot, this.endpoints.getEndpointDomain('cdn').replace('https://', ''));
      const nextAlignment: ArtistsJobsResourcesAlignment = {
        resource_id: this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].id,
        url: this.alignmentPos.data.screenshot,
        config_json: this.alignmentPos,
        public: this.rolesHelper.isSULogedin(),
        data_id: this.images[this.currentImageIndex].id,
        combineImagesMode: this.feedbackMode
      };
      if (!nextAlignment.config_json.data?.deltaLAB) {
        await this.saveNextAlignment(nextAlignment);
        if (this._currentOrigin === ALIGNMENT_ORIGIN.CAMERA_BUTTON) {
          setTimeout(() => {
            this.jumpToTab = AssetAdjustmentSector.ALIGNMENTS;
          });
        }
      } else {
        this.colorSamplingService.alignment = nextAlignment;
        this.alignmentProcess = false;
        this.colorSamplingService.testing = false;
      }
      if (this._currentOrigin === ALIGNMENT_ORIGIN.ACCURACY_TEST) {
        this.assetAdjustmentsService.toggleNoDistanceLimit(false);
        this.assetAdjustmentsService.broadcastSceneSummary();
        this.assetAdjustmentsService.toggleCollisionsVisibility(true);
      }
      delete this._currentOrigin;
    }
  }

  private async saveNextAlignment(nextAlignment: ArtistsJobsResourcesAlignment): Promise<void> {
    if (nextAlignment.url?.indexOf('data:') === 0) {
      nextAlignment.url = await this.utils.getUrlFromBase64(nextAlignment.url);
    }
    if (nextAlignment.config_json?.data?.screenshot && nextAlignment.config_json.data.screenshot.indexOf('data:') === 0) {
      nextAlignment.config_json.data.screenshot = nextAlignment.url || await this.utils.getUrlFromBase64(nextAlignment.config_json.data.screenshot);
    }
    if (nextAlignment.config_json?.data)
      nextAlignment.config_json.data.origin = this._currentOrigin;
    const alignment = await this.utils.observableToPromise(this.rest.jobResourceAlignment('post', nextAlignment));
    alignment.artists_users = [this.auth.user];
    alignment.rolesUI = this.rolesHelper.roleAbbreviation(this.auth.user.artists_users_roles);
    alignment.created_at = new Date();
    if (typeof alignment.config_json === 'string' || alignment.config_json instanceof String) {
      alignment.config_json = JSON.parse(alignment.config_json);
    }
    const jobItem = this.jobService.job.artists_jobs_items.filter(i => i.id === this.jobService.currentArtistsjobitemId)[0].artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments;
    const jobsResourcesAlignments = this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments;
    if (jobsResourcesAlignments.filter(el => el.reuse_alignment).length) {
      const index = jobsResourcesAlignments.indexOf(jobsResourcesAlignments.filter(el => el.reuse_alignment)[0]);
      jobsResourcesAlignments.splice(index, 0, alignment)
    } else {
      this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_alignments.unshift(alignment);
    }
    jobItem.unshift(alignment);
    if (this._currentOrigin === ALIGNMENT_ORIGIN.CAMERA_BUTTON) {
      let text = 'Alignment screenshot successfully saved';
      if (this.alignmentPos?.data?.pixelsDiffPercentage) {
        text += `(${this.decimalPipe.transform(this.alignmentPos.data.pixelsDiffPercentage, '1.0-1')}% accuracy)`;
      }
      const msg: Notification = {
        text,
        type: NotificationType.Success,
        action: 'OK'
      };
      this.utils.notifyUser(msg);
    }
    this.pixels.sendPixel({
      event: 'alignment_accuracy',
      percentage: this.alignmentPos.data.pixelsDiffPercentage,
      opaque_percentage: this.alignmentPos.data.pixelsDiffOpaquePercentage,
      color_accuracy: this.alignmentPos.data.colorAccuracy,
      opaque_color_accuracy: this.alignmentPos.data.opaqueColorAccuracy,
      alignment_id: alignment.id,
      job_id: this.jobService.job?.id,
      delta_ab: this.alignmentPos.data.deltaLAB?.averageABDelta,
      delta_l: this.alignmentPos.data.deltaLAB?.averageLightnessDelta,
      delta_e: this.alignmentPos.data.deltaLAB?.deltaE
    });
    delete this.alignmentPos;
    // delete this.alignmentScreenshot;
    this.alignmentProcess = false;
    if (this._currentOrigin === ALIGNMENT_ORIGIN.CAMERA_BUTTON) {
      this.jumpToTab = null;
      setTimeout(() => {
        this.jumpToTab = AssetAdjustmentSector.ALIGNMENTS;
      });
    }
  }

  public getScreenshot(): void {
    this.generateScreenshot++
  }

  public onAlignment(alignment: ArtistsJobsResourcesAlignment): void {
    this.alignment = alignment;
  }

  public closeAlignment(): void {
    delete this.alignment;
    this.jobService.onModelIndex();
    this.assetAdjustmentsService.firstInit();
  }

  public async updateCollision(): Promise<void> {
    const img = this.images[this.currentImageIndex];
    if (!img?.id) {
      return;
    }
    const resourceId = this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].id;
    const imageDim = await this.utils.getImageDim(img.big)
    this.collisionService.setAssetData(resourceId, img.id, imageDim);
  }

  public changeMode(mode: ResourceMode): void {
    this.currentMode = mode;
    this.aHelperService.modelOpacity = 1;
    this.enableAllCombineImagesModeToggles();
    this.hide3DassetsDropdown = false;
    this.collisionService.alignmentSection = this.collisionService.ALIGNMENT_SECTION.CHOOSE;
    this.collisionService.render = undefined;

    switch (this.currentMode) {
      case ResourceMode.ALIGNMENT: {
        this.feedbackMode = CombineImagesMode.SIDE_BY_SIDE;
        setTimeout(() => {
          this.collisionService.alignmentRequest = undefined;
          this.updateCollision();
        }, 100);
        this.openModelAccuracyTutorialWithDelay();
        break;
      }
      case ResourceMode.COLOR_SAMPLING: {
        if (this.feedbackMode === CombineImagesMode.MODEL_ONLY) {
          this.feedbackMode = CombineImagesMode.SIDE_BY_SIDE;
        }
        this.disableCombineImagesModeToggle(CombineImagesMode.MODEL_ONLY);
        this.openColorSampleTutorialWithDelay();
        this.hide3DassetsDropdown = true;
        break
      }
    }
    this.checkIfShouldShow3dAssetsDropdown();
  }

  private enableAllCombineImagesModeToggles(): void {
    this.combineImagesModeToggles.forEach(btn => btn.disabled = false);
  }

  private disableCombineImagesModeToggle(combineImagesMode: CombineImagesMode): void {
    const index = this.combineImagesModeToggles.findIndex(toggle => toggle.value === combineImagesMode);
    this.combineImagesModeToggles[index].disabled = true;
  }

  async onReduce(config: ResourcePolygonReduction): Promise<void> {
    await this.resourcePolygonReductionService.reduce(config);
    this.changeMode(ResourceMode.ADJUSTMENTS);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private openColorSampleTutorialWithDelay(): void {
    setTimeout(() => {
      this.colorSampleTutorial(true);
    }, 1000);
  }

  private openModelAccuracyTutorialWithDelay(): void {
    setTimeout(() => {
      this.modelAccuracyTutorial(true);
    }, 1000);
  }

  public get model(): string {
    return this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].viewer_url;
  }

  public onColorSampleImageChange(imageId: number): void {
    const imageIndex = this.images.findIndex(image => image.id === imageId);
    if (imageIndex > -1) {
      this.currentImageIndex = imageIndex
    }
  }

  public get showAlignmentCompareResult(): boolean {
    return !!(this.isAlignmentMode && this.collisionService.alignmentRenderResponse);
  }

  public get isCollisionSuccess(): boolean {
    return this.collisionService.isSuccess();
  }

  public onAlignmentImageSelected(): void {
    this.collisionService.alignmentSection = this.collisionService.ALIGNMENT_SECTION.MATCH;
  }

  public get referenceImageChosen(): boolean {
    return this.collisionService.alignmentSection !== this.collisionService.ALIGNMENT_SECTION.CHOOSE;
  }

  public get isAlignmentNextButtonDisabled(): boolean {
    return this.collisionService.alignmentSection !== this.collisionService.ALIGNMENT_SECTION.CHOOSE;
  }

  public get alignmentResponseScore(): number {
    return this.collisionService?.alignmentRenderResponse?.score || 0;
  }

  public checkIfShouldShow3dAssetsDropdown(): void {
    this.show3dAssetsDropdown = this.jobService.currentArtistsjobitem.artists_jobs_resources?.length && !this.isAlignmentMode &&
    (this.currentMode === this.resourceMode.COLOR_SAMPLING || this.currentMode === this.resourceMode.ADJUSTMENTS);
  }

  public onResourceSelect(resource: Resource): void {
    const index = this.jobService.currentArtistsjobitem.artists_jobs_resources.indexOf(resource);
    this.jobService.currentModelIndex = index;
    this.jobService.onModelIndex();
    this.updateCollision();
  }

  public colorSampleTutorial(withIdentifier?: boolean): void {
    const options = new TutorialOptions();
    const stages: TutorialStage[] = [
      {
        title: 'CHOOSE REFERENCE IMAGE:',
        desc: 'Select a reference image to conduct the color sampling test.',
        selector: 'div.bottom-gallery app-simple-carousel-slider',
        left: '0',
        top: '-20px',
        position: 'above',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'ALIGN MODEL WITH THE REFERENCE IMAGE',
        desc: 'Rotate and zoom the model to align it with the selected reference image, while clicking and holding the ‘Space-key’. Make sure the model is aligned as possible.',
        selector: 'iframe',
        top: '0',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'SELECT PRESET',
        desc: `Select a preset to sample your model with. You can download the HDRI of the selected preset (if available),
         by clicking on the “Download selected” button.`,
        tip: 'You can add the HDRI to your 3D software, it will help you to better adjust the model’s colors.',
        selector: 'div.color-sampling-status__presets',
        top: '15px',
        left: '0',
        fixPadding: true
      },
      {
        title: 'SELECT COLOR SAMPLE SIZE',
        desc: 'Select the dimensions that suit best the model’s texture and the reference image.',
        tip: 'You can use a small sample size for solid textures, and a bigger one for more complexed textures.',
        selector: 'div.color-sampling-status__sample-size',
        top: '15px',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end',
        fixPadding: true
      },
      {
        title: 'SAMPLE COLOR',
        desc: 'Click on the model to compare its color values with the image reference.',
        selector: 'app-model-image-compare div.wrap',
        top: '0',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'SAVE TEST',
        desc: 'Click the button to save your test results. To complete the test, you must save a successful sampling.',
        selector: 'div.color-sampling-status__buttons div',
        top: '0',
        left: '0',
        position: 'above',
        fixPadding: true
      }
    ];
    if (withIdentifier) {
      options.identifier = 'color-sample-test';
    }
    this.tutorialService.setTutorial(stages, options);
  }

  public modelAccuracyTutorial(withIdentifier?: boolean): void {
    const options = new TutorialOptions();
    const stages: TutorialStage[] = [
      {
        title: 'CHOOSE REFERENCE IMAGE:',
        desc: 'Select a reference image to conduct the color sampling test.',
        selector: 'div.bottom-gallery app-simple-carousel-slider',
        left: '0',
        top: '-20px',
        position: 'above',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'ALIGN MODEL WITH THE REFERENCE IMAGE',
        desc: 'Rotate and zoom the model to align it with the selected reference image, while clicking and holding the ‘Space-key’. Make sure the model is aligned as possible.',
        selector: 'iframe',
        top: '0',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'PLACE DOT',
        desc: `Place a dot by clicking on the image reference’s bounding-box.`,
        tip: 'Best to place the dot on the the image edges, in an apparent position for both the image reference and the model.',
        selector: 'app-model-image-compare div.image-wrap.pos-rel',
        top: '0',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'MATCH DOT',
        desc: 'Place a matching dot by clicking on the 3D model.',
        tip: 'The dots needs to be in the exact same place. Make sure to click on the model itself, not on the background.',
        selector: 'iframe',
        top: '0',
        left: '0',
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'PAIR MATCHED SUCCESSFULLY!',
        desc: 'Continue to match the remaining pairs of dots to complete the accuracy test.',
        tip: 'Place the pairs on different areas in order to get a good score on the test.',
        selector: 'app-collision-status .model-accuracy-status__content-container > div',
        selectorIndex: 0,
        top: '0',
        left: '0',
        fixPadding: true,
        scrollToPrompt: true,
        scrollTo: 'end'
      },
      {
        title: 'CLICK NEXT TO CONTINUE',
        desc: 'Once all 6 pairs are matched, click “Compare” to receive the test’s result.',
        selector: 'app-collision-status .model-accuracy-status__buttons',
        top: '0',
        left: '0',
        fixPadding: true,
        position: 'above',
        scrollToPrompt: true,
        scrollTo: 'end'
      }
    ];
    if (withIdentifier) {
      options.identifier = 'model-accuracy-test';
    }
    this.tutorialService.setTutorial(stages, options);
  }
}
