import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ALIGNMENT_STATE } from '../../job/resource';
import { CollisionService } from '../collision.service';
import { AccuracyStep } from './collision-step-indicator.model';

@Component({
    selector: 'app-collision-step-indicator',
    templateUrl: './collision-step-indicator.component.html',
    styleUrls: ['./collision-step-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class CollisionStepIndicatorComponent implements OnInit {

  constructor(private _collisionService: CollisionService) {}

  ngOnInit(): void {
  }

  public get AccuracyStep(): typeof AccuracyStep {
    return AccuracyStep
  }

  public get accuracyStep(): AccuracyStep {
    switch (this._collisionService.alignmentState) {
      case ALIGNMENT_STATE.IMAGE: {
        if (this.points2d.length === this._collisionService.minPoints) {
          return AccuracyStep.allPlaced;
        } else {
          return AccuracyStep.placeDot;
        }
      }
      case ALIGNMENT_STATE.TRANSITION: {
        if (this.points2d.length === this.points3d.length) {
          if (this.points2d.length === this._collisionService.minPoints) {
            return AccuracyStep.allPlaced;
          } else {
            return AccuracyStep.pairMatched;
          }
        } else {
          return AccuracyStep.dotPlaced;
        }
      }
      case ALIGNMENT_STATE.VIEWER: {
        return  AccuracyStep.matchDot;
      }
    }
  }

  private get points2d(): number[][] {
    return this._collisionService.alignmentRequest?.points_2d || [];
  }

  private get points3d(): number[][] {
    return this._collisionService.alignmentRequest?.points_3d || [];
  }
}
