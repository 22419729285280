import { ChangeDetectionStrategy, Component, Input, OnChanges, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ArtistsJobsType } from 'app/job/job';
import { KeyValuePair, JobsTypes } from 'app/shared/enums';
import { PixelsService } from 'app/shared/pixels.service';
import { IntersectionObserverOptions } from 'app/shared/utils';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-general-specs',
    templateUrl: './general-specs.component.html',
    styleUrls: ['./general-specs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GeneralSpecsComponent implements OnChanges, AfterViewInit {
  @ViewChild('el') el: ElementRef;
  @Input() types: Array<ArtistsJobsType>;
  @Input('job-id') jobId: number;
  @Input('offer-id') offerId: number;
  public links: Array<KeyValuePair>;
  constructor(
    private pixels: PixelsService,
    private utils: UtilsService
  ) {}

  ngAfterViewInit() {
    this.initIntersectionObserver();
  }

  async initIntersectionObserver() {
    let o = {
      once: true,
      threshold: 1.0
    } as IntersectionObserverOptions;
    await this.utils.onViewportVisibility(this.el, o);
    this.pixels.sendPixel({
      event: 'guideline_view',
      job_id: this.jobId,
      offer_id: this.offerId,
      is_general: true
    });
  }

  ngOnChanges(): void { 
    this.links = [];
    this.types.forEach(t => {
      const l = this.getLink(t);
      if (l)
        this.links.push(l);
    });
  }

  getLink(ajt: ArtistsJobsType): KeyValuePair {
    switch (ajt.type_id) {
      case JobsTypes.GEOMETRY: {
        return {
          key: 'Creators3D General Geometry Guide',
          value: 'https://creators3d.zendesk.com/hc/en-us/articles/9796845269917'
        };
      }
      case JobsTypes.TEXTURE: {
        return {
          key: 'Creators3D General Texture Guide',
          value: 'https://creators3d.zendesk.com/hc/en-us/articles/9277349053981-Texture-general-guidelines'
        };
      }
      // case JobsTypes.RENDR: {
      //   return {
      //     key: 'General Render Guide',
      //   };
      // }
    }
  }

  onClick(c: KeyValuePair) {
    this.pixels.sendPixel({
      event: 'click',
      button_name: c.key,
      job_id: this.jobId,
      offer_id: this.offerId
    });
  }
}
