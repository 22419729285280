import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Rank } from '../rank';

@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    standalone: false
})
export class RankingComponent implements OnInit, OnChanges {
  @Input() id: any;
  @Input() rank: number;
  @Input() max: number;
  // @Input() char: string;
  @Input('zero-based') zeroBased: boolean;
  @Input('edit-mode') editMode: boolean;
  @Input('star-width') starWidth: number;
  @Output('on-change') onChange: EventEmitter<Rank>;
  public dummyArray: Array<number>;
  public halfIndex: number;
  public fixedRank: number;
  public roundRank: number;
  public starMarg: number;
  constructor() {
    this.onChange = new EventEmitter<Rank>();
    this.starWidth = 20;
    this.setStarMarg();
  }
  ngOnInit() {
    
  }

  init() {
    this.dummyArray = [];
    for (let i = 0; i < this.max; i++) {
      this.dummyArray.push(i);
    }
    this.fixedRank = this.rank;
    // if (!this.char)
    //   this.char = '☆';
    if (this.rank) {
      this.roundRank = this.rank;
      let strRank = String(this.roundRank);
      if (strRank.indexOf('.') > -1)
        this.roundRank = parseFloat(strRank.substring(0, strRank.indexOf('.') + 2));
      let num = Math.floor(this.rank);
      let r = this.rank - num;
      if (r > 0.25 && r < 0.75)
        this.halfIndex = this.max - (num + 1);
      else if (this.rank >= ((this.max - 1) + 0.25) && this.rank < (this.max - 0.25))
        this.halfIndex = 0;
      else if (this.rank >= (this.max - 0.25)) {
        delete this.halfIndex;
        this.fixedRank = this.max;
      }
      else if (r > 0.75 && Math.floor(this.rank) < this.max) {
        delete this.halfIndex;
        this.fixedRank = Math.floor(this.rank) + 1;
      }
      else
        delete this.halfIndex;
    }
    this.setStarMarg();
  }

  ngOnChanges() {
    this.init();
  }

  setStarMarg() {
    this.starMarg = this.starWidth / 5 + 5;
  }

  setRank(rank: number) {
    if (this.rank == rank) // disable current rank - set to null
      this.rank = null;
    else
      this.rank = rank;
    let output = {
      id: this.id,
      value: this.rank
    } as Rank;
    this.fixedRank = this.rank;
    this.onChange.emit(output);
  }

  isZero(): boolean {
    return typeof this.rank === 'number' && this.rank >= 0;
  }
}
