import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { User, UsersFilterOptions } from '../../auth/user';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
// import { RestService } from '../../communication/rest.service';
import { GraphqlService } from '../../communication/graphql.service';

@Component({
    selector: 'app-users-autocomplete',
    templateUrl: './users-autocomplete.component.html',
    styleUrls: ['./users-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class UsersAutocompleteComponent implements OnInit, OnDestroy {
  // @Input() users: Array<User>;
  @Input('retailer-id') retailerId: number;
  @Input('clear-on-select') clearOnSelect: boolean;
  @Input('is-group-leader') isGroupLeader: boolean;
  @Input('user-id') userId: number;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input('show-all') showAll: boolean;
  @Input('permission') permission: Array<string>;
  @Input('roles') roles: Array<number>;
  @Input('panelWidth') panelWidth: string | number;
  @Output('user-change') userChange: EventEmitter<User>;
  @ViewChild('filterInput') filterInput: ElementRef;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  public users: Array<User>;
  public userCtrl: FormControl;
  public filteredItems: Observable<Array<User>>;
  public current: User;
  private sub: Subscription;
  private currentFetch: Subscription;
  constructor(
    private gql: GraphqlService
  ) {
    this.users = [];
    this.userCtrl = new FormControl();
    this.userChange = new EventEmitter<User>();
  }

  ngOnInit() {
    if (typeof this.showAll !== 'boolean')
      this.showAll = false;
    this.placeholder = this.placeholder || '';
    this.sub = this.userCtrl.valueChanges.subscribe(this.fetch.bind(this));
    this.setFilteredItems();
    // if (this.users)
    //   this.init();
    // else {
    //   this.rest.userProfile('get').subscribe(
    //     users => {
    //       this.users = users as Array<User>;
    //       this.init();
    //     }
    //   )
    // }
    this.placeholder = this.placeholder || 'users';
  }

  fetch() {
    if (this.filterInput.nativeElement.value) {
      let options = {
        first_name: this.filterInput.nativeElement.value,
        last_name: this.filterInput.nativeElement.value,
        name: this.filterInput.nativeElement.value,
        limit: 50,
        offset: 0,
        show_all: this.showAll
      } as UsersFilterOptions;
      if(this.isGroupLeader){
        options.is_group_leader = true;
      }
      if (this.roles) {
        options.role_id = this.roles;
      }
      if (this.permission)
        options.permission = this.permission;
      let id = Number(this.filterInput.nativeElement.value);
      if (!isNaN(id) && id) {
        options.id = [id];
        delete options.first_name;
        delete options.last_name;
        delete options.name;
      }
      if (this.currentFetch)
        this.currentFetch.unsubscribe();
      if (this.retailerId) {
        options.retailer_id = this.retailerId;
      }
      this.currentFetch = this.gql.users(options).subscribe(
        obj => {
          this.users = obj.data.allArtistsUsers.rows;
          this.setFilteredItems();
        }
      )
    }
    else {
      this.users = [];
    }
  }

  init() {
    this.setFilteredItems();
    if (this.userId && this.users.length > 0) {
      this.users.forEach((o) => {
        if (o.id == this.userId) {
          this.userCtrl.setValue(o);
          this.current = o;
          return false;
        }
      });
    }
  }

  clear(): Array<User> {
    if (!this.filterInput || !this.filterInput.nativeElement || !this.filterInput.nativeElement.value) {
      this.current = null;
      this.userChange.emit(this.current);
    }
    return this.users.slice();
  }

  filterItems(filterUser: any) {
    if (typeof filterUser === 'string')
      return this.users.filter(user =>
        user.name && user.name.toLowerCase().indexOf(filterUser.toLowerCase()) === 0);
    return this.users.filter(user =>
      user.name && user.name.toLowerCase().indexOf(filterUser.name.toLowerCase()) === 0);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.current = event.option.value;
    this.userChange.emit(this.current);
    if (this.clearOnSelect) {
      this.userCtrl.setValue(null);
      this.setFilteredItems();
      setTimeout(() => {
        this.filterInput.nativeElement.blur();
      }, 0);
    }
  }

  setFilteredItems() {
    this.filteredItems = this.userCtrl.valueChanges
      .pipe(
        startWith(''),
        map(user => user ? this.filterItems(user) : this.clear())
      );
  }

  displayWith(state) {
    let res = '';
    if (state)
      res = state.name;
    else if (this.current)
      res = this.current.name;
    return res;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
