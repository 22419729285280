import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IComplexitySelection, IGeometryComplexitySummery } from '../offer-creation-tool/offer-creation-tool.interface';

@Component({
    selector: 'app-complexity-level-dialog',
    templateUrl: './complexity-level-dialog.component.html',
    styleUrls: ['./complexity-level-dialog.component.scss'],
    standalone: false
})
export class ComplexityLevelDialogComponent {
  public productId: number;
  public subCategoryId: number;
  private summery: IGeometryComplexitySummery;
  constructor(
    private dialogRef: MatDialogRef<ComplexityLevelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.summery = {} as IGeometryComplexitySummery;
    this.productId = data.productId;
    this.subCategoryId = data.subCategoryId;
  }

  public onComplexityLevelSelected(item: IComplexitySelection) {
    this.summery.selection = item;
    this.onChange();
  }

  public onPrice(price: number) {
    this.summery.price = price;
    this.onChange();
  }

  public onComplexity(complexity: number) {
    this.summery.complexity = complexity;
    this.onChange();
  }

  private onChange() {
    if (this.summery.complexity && this.summery.price && this.summery.selection) {
      this.dialogRef.close(this.summery);
    }
  }
}
