import { Component } from '@angular/core';
import { KeyValueAnyPair } from 'app/shared/enums';
import { ChatHistoryType } from 'app/auth/user';
import { JobService } from 'app/job/job.service';
import { AuthService } from 'app/auth/auth.service';
import { Job } from 'app/job/job';
import { ChatService } from '../chat.service';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    standalone: false
})
export class SupportComponent {
  public isOpen: boolean;
  public butons: Array<KeyValueAnyPair>;
  public jobs: Array<Job>;
  public showJobs: boolean;
  constructor(
    private jobService: JobService,
    private auth: AuthService,
    private utils: UtilsService,
    private chatService: ChatService
  ) {
    this.getJobs();
  }

  setButtons() {
    if (this.jobs && this.jobs.length) {
      this.butons = [
        {
          key: 'Payments Issue',
          value: {
            type: ChatHistoryType.PAYMENTS,
            css: 'large'
          }
        },
        {
          key: 'Upload a Model',
          value: {
            type: ChatHistoryType.JOB_CHAT,
            css: 'small'
          }
        },
        {
          key: 'Get Your Advisor',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'small'
          }
        },
        {
          key: 'Guide tour the platform',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'large'
          }
        },
        {
          key: 'Feedback Explanation',
          value: {
            type: ChatHistoryType.JOB_CHAT,
            css: 'large'
          }
          
        },
        {
          key: 'Other',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'small'
          }
        },
        {
          key: 'Report a bug',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'small'
          }
        },
        {
          key: 'Appealing on Feedback',
          value: {
            type: ChatHistoryType.JOB_CHAT,
            css: 'large'
          }
        }
      ];
    }
    else {
      this.butons = [
        {
          key: 'Payments Issue',
          value: {
            type: ChatHistoryType.PAYMENTS,
            css: 'large'
          }
        },
        {
          key: 'Get Your Advisor',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'small'
          }
        },
        {
          key: 'Guide tour the platform',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'small'
          }
        },
        {
          key: 'Other',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'large'
          }
        },
        {
          key: 'Report a bug',
          value: {
            type: ChatHistoryType.SUPPORT,
            css: 'large'
          }
        }
      ];
    }
  }

  toggle() {
    this.showJobs = false;
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.getJobs();
    }
  }

  selectJob(job: Job) {
    this.chatService.openChatByJobId(job.id);
    this.toggle();
  }

  select(btn: KeyValueAnyPair) {
    
    switch (btn.value.type) {
      case ChatHistoryType.JOB_CHAT: {
        this.showJobs = true;
        break;
      }
      case ChatHistoryType.PAYMENTS: {
        // Currently we want to use the support chat only
        // this.chatService.paymentRequest();
        this.chatService.supportRequest();
        this.toggle();
        break;
      }
      case ChatHistoryType.SUPPORT: {
        this.chatService.supportRequest();
        this.toggle();
        break;
      }
      case ChatHistoryType.PERSONAL: {
        break;
      }
      case ChatHistoryType.GROUP: {
        break;
      }
    }
  }

  

  getJobs() {
    this.jobService.getArtistActiveJobs(this.auth.user.id).subscribe(
      j => {
        this.jobs = this.utils.deepCopyByValue(j.data.allJobs.rows);
        this.jobs.forEach(j => {
          this.jobService.mapUI(j, false);
        });
        this.setButtons();
      }
    )
  }
}
