import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MeshLibraryService } from '../mesh-library.service';
import { MeshLibraryState, MeshLibraryWrapper } from '../mesh-library';

@Component({
    selector: 'app-mesh-library',
    templateUrl: './mesh-library.component.html',
    styleUrls: ['./mesh-library.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MeshLibraryComponent implements OnInit, OnDestroy {
  @Input('mesh-library-wrapper') meshLibraryWrapper: MeshLibraryWrapper;
  public terms: boolean;

  get MeshLibraryState() {
    return MeshLibraryState;
  }

  constructor(
    public meshLibService: MeshLibraryService
  ) { }

  ngOnInit() {
    this.meshLibService.init(this.meshLibraryWrapper);
  }

  ngOnDestroy() {
    this.meshLibService.ngOnDestroy();
  }

  /**
   * Return the correct button name for download files button
   */
  public getDownLoadFileButtonName(): string {
    return (this.meshLibService.selectedParts?.length > 1) ? `download files` : `download file`;
  }
}
