import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { User, ArtistsUsersSoftware } from '../user';
import { RestService } from 'app/communication/rest.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationType, Notification } from 'app/shared/notifications';
import { Software } from 'app/softwares/softwares';
import { SoftwaresService } from 'app/softwares/softwares.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-artist-softwares',
    templateUrl: './artist-softwares.component.html',
    styleUrls: ['./artist-softwares.component.scss'],
    standalone: false
})
export class ArtistSoftwaresComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: User;
  @Input('enum-id') enumId: number;
  @Input('autosave') autosave: boolean;
  @Input('valid-source-file-software') validSourceFileSoftware: number[];
  @Output('on-change') onSelectionChange: EventEmitter<Array<ArtistsUsersSoftware>>;
  public artists_users_software: Array<ArtistsUsersSoftware>;
  public softwaresDictionary: { [id: number]: Software; };
  private subs: Array<Subscription>;
  constructor(
    private rest: RestService,
    private broadcaster: BroadcasterService,
    private softwaresService: SoftwaresService,
    private utils: UtilsService
  ) {
    this.subs = [];
    this.onSelectionChange = new EventEmitter<Array<ArtistsUsersSoftware>>();
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.softwaresService.getCachedSoftwares())
      this.setArtistsUsersSoftware();
    else
      this.subs.push(this.broadcaster.on('onArtistsSoftwaresEnum').subscribe(this.setArtistsUsersSoftware.bind(this)));
  }

  setArtistsUsersSoftware() {
    const softwares = this.softwaresService.getCachedSoftwares();
    // let relevant = [] as Array<ArtistsUsersSoftware>;
    this.artists_users_software = [];
    softwares.forEach(se => {
      if (se.enum_id == this.enumId) {
        if (this.user && this.user.artists_users_software){
          let softItem = this.user.artists_users_software.find(aus => aus.software_id == se.id);
          if (softItem) {
            softItem.software_name = se.software_name;
            softItem.is_valid = this.isSoftwareValid(se);
            this.artists_users_software.push(softItem);
          }
        }
        else
          this.artists_users_software.push({ software_id: se.id, is_qualified: false, is_valid: this.isSoftwareValid(se), software_image_url: se.software_image_url, software_name: se.software_name });
      }
    });
    this.softwaresDictionary = this.softwaresService.getSoftwaresDictionary();
    // this.artists_users_software = this.user.artists_users_software.filter(aus => aus.software_id == this.enumId);
  }

  // getType(t: SoftwareType) {
  //   switch (t) {
  //     case SoftwareType.CREATION_SOFTWARE: {
  //       return 'software';
  //     }
  //     case SoftwareType.RENDERING_ENGINE: {
  //       return 'rendering engine';
  //     }
  //     case SoftwareType.EXPERTISE: {
  //       return 'expertise';
  //     }
  //   }
  // }

  isSoftwareValid(s: Software) {
    if (this.validSourceFileSoftware == null) {
      return true;
    }
    return this.validSourceFileSoftware.includes(s.id)
  }

  onChange(s: ArtistsUsersSoftware) {
    if(s.is_valid) {
      s.is_qualified = !s.is_qualified;
      if (this.autosave) {
        this.save();
      }
      this.onSelectionChange.next(this.artists_users_software);
    }
  }

  save() {
    this.user.artists_users_software = this.artists_users_software;
    this.rest.userProfile('put', this.user, '?uid=' + this.user.id).subscribe(
      () => {
        let data: Notification = {
          text: 'abilities updated successfully',
          type: NotificationType.Success,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
      },
      err => {
        this.utils.httpErrorResponseHandler(err, 'failure saving abilities');
      }
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
