import { Component, Input, OnDestroy } from '@angular/core';
import { ColorSamplingService } from '../color-sampling.service';
import { JobService } from '../../job/job.service';
import { CombineImagesResponse, ArtistsJobsResourcesColorComparisonUI } from '../../job/resource';
import { AB_THRESHOLD, LIGHTNESS_THRESHOLD } from 'app/shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { ColorSamplingHelpModalComponent } from '../color-sampling-help-modal/color-sampling-help-modal.component';
import { SAMPLE_STATUS_TEXT, SampleStatus, SampleStatusText } from './color-sampling-result.model';
import { RenderingEngine } from 'asset-adjustments';
import { Subscription } from 'rxjs';
import { AdjustmentsPreset } from '../asset-adjustments';
import { UtilsService } from 'app/shared/utils.service';

@Component({
    selector: 'app-color-sampling-result',
    templateUrl: './color-sampling-result.component.html',
    styleUrls: ['./color-sampling-result.component.scss'],
    standalone: false
})
export class ColorSamplingResultComponent implements OnDestroy {
  static PADDING = 30;
  @Input() set sample(sample: CombineImagesResponse) {
    this._sample = sample;
    if (this._sample) {
      this.updateResult();
    }
    if (this._sample) {
      this.redBottomThreshold = this.sample.deltaLAB.resourceMinRGB.red - ColorSamplingResultComponent.PADDING;
      this.redTopThreshold = this.sample.deltaLAB.resourceMaxRGB.red + ColorSamplingResultComponent.PADDING;
      this.greenBottomThreshold = this.sample.deltaLAB.resourceMinRGB.green - ColorSamplingResultComponent.PADDING;
      this.greenTopThreshold = this.sample.deltaLAB.resourceMaxRGB.green + ColorSamplingResultComponent.PADDING;
      this.blueBottomThreshold = this.sample.deltaLAB.resourceMinRGB.blue - ColorSamplingResultComponent.PADDING;
      this.blueTopThreshold = this.sample.deltaLAB.resourceMaxRGB.blue + ColorSamplingResultComponent.PADDING;
      this.colorSamplingService.resetInstanceSession();
      this.colorSamplingService.pixels.sendPixel({
        event: 'click',
        button_name: 'color_test_sample',
        color_test_session: this.colorSamplingService.session,
        color_test_session_instance: this.colorSamplingService.instanceSession,
        color_test_value: this.colorSamplingService.colorComparisonFartory(),
      });
    }
  }
  @Input() testSucceeded: boolean;
  public sampleStatus: SampleStatus;
  public referenceSampleImageUrl: string;
  public resourceSampleImageUrl: string;
  public brightnessFailMessage: string;
  public hueFailMessage: string;
  public fixing: boolean;
  public saving: boolean;
  public reverting: boolean;
  public redBottomThreshold: number;
  public redTopThreshold: number;
  public greenBottomThreshold: number;
  public greenTopThreshold: number;
  public blueBottomThreshold: number;
  public blueTopThreshold: number;
  public savedTests: Array<ArtistsJobsResourcesColorComparisonUI>;
  private _sample: CombineImagesResponse;
  private _fixes: Array<Array<any>>;
  private onJobRefreshSub: Subscription;
  private _currentPreset: AdjustmentsPreset;
  constructor(public colorSamplingService: ColorSamplingService,
              public jobService: JobService,
              private dialog: MatDialog,
              private utils: UtilsService) {
    this._fixes = [];
    this.colorSamplingService.saved = false;
    this.colorSamplingService.pixels.sendPixel({
      event: 'colorTestSession',
      color_test_session: this.colorSamplingService.session
    });
    this.buildTests();
  }

  public get sample(): CombineImagesResponse {
    return this._sample;
  }

  public get sampleStatusText(): SampleStatusText {
    return SAMPLE_STATUS_TEXT;
  }

  public get SampleStatus(): typeof SampleStatus {
    return SampleStatus;
  }

  private buildTests() {
    this.savedTests = this.jobService.currentArtistsjobitem.artists_jobs_resources[this.jobService.currentModelIndex].artists_jobs_resources_color_comparisons.sort((a, b) => {return b.id - a.id});
  }

  private updateResult(): void {
    const brightnessFailed = this.sample.deltaLAB.averageLightnessDelta > LIGHTNESS_THRESHOLD;
    const hueFailed = this.sample.deltaLAB.averageABDelta > AB_THRESHOLD;

    if (brightnessFailed) {
      this.setBrightnessFailedMessage();
    }

    if (hueFailed) {
      this.setHueFailedMessage();
    }

    this.referenceSampleImageUrl = this.sample.deltaLAB.referenceSampleImageUrl;
    this.resourceSampleImageUrl = this.sample.deltaLAB.resourceSampleImageUrl;
    this.sampleStatus = hueFailed || brightnessFailed ? SampleStatus.failed : SampleStatus.success;
  }

  public openHelp(): void {
    this.dialog.open(ColorSamplingHelpModalComponent, {
      width: '600px',
      height: '90%'
    });
  }

  public async fix() {
    this.colorSamplingService.pixels.sendPixel({
      event: 'click',
      button_name: 'color_test_fix',
      color_test_session_instance: this.colorSamplingService.instanceSession,
      color_test_session: this.colorSamplingService.session
    });
    this.fixing = true;
    this.colorSamplingService.testing = true;
    const red = this.sample.deltaLAB.referenceAverageAB.RGBY.red - this.sample.deltaLAB.resourceAverageAB.RGBY.red,
      green = this.sample.deltaLAB.referenceAverageAB.RGBY.green - this.sample.deltaLAB.resourceAverageAB.RGBY.green,
      blue = this.sample.deltaLAB.referenceAverageAB.RGBY.blue - this.sample.deltaLAB.resourceAverageAB.RGBY.blue;
    const t = {
      minRed: Math.max(this.redBottomThreshold, 0),
      maxRed: Math.min(this.redTopThreshold, 255),
      minGreen: Math.max(this.greenBottomThreshold, 0),
      maxGreen: Math.min(this.greenTopThreshold, 255),
      minBlue: Math.max(this.blueBottomThreshold, 0),
      maxBlue: Math.min(this.blueTopThreshold, 255)
    };
    await this.colorSamplingService.aAS.adjustImageRGB(null, red, green, blue, t);
    this._fixes.push([null, red, green, blue, t]);
    this.colorSamplingService.testing = false;
    this.colorSamplingService.fixed = true;
    this.fixing = false;
  }

  public async saveFix() {
    this.colorSamplingService.pixels.sendPixel({
      event: 'click',
      button_name: 'color_test_save_fix',
      color_test_session_instance: this.colorSamplingService.instanceSession,
      color_test_session: this.colorSamplingService.session
    });
    this.saving = true;
    this.colorSamplingService.testing = true;
    this._currentPreset = this.colorSamplingService.aAHS.currentPreset;
    if (this.colorSamplingService.aAS.engine === RenderingEngine.BABYLON) {
      this.colorSamplingService.aAS.engine = RenderingEngine.THREE;
      await this.colorSamplingService.aAS.broadcastViewerFullyLoaded();
      for (let i = 0; i < this._fixes.length; i++) {
        await this.colorSamplingService.aAS.adjustImageRGB.apply(this.colorSamplingService.aAS, this._fixes[i]);
      }
    }
    if (!this.onJobRefreshSub)
      this.onJobRefreshSub = this.jobService.onJobRefresh.subscribe(this.applyPreset.bind(this));
    this.colorSamplingService.aAS.export({ useOptipng: !this.jobService.isAmazon() });
  }

  public async revert() {
    this.colorSamplingService.pixels.sendPixel({
      event: 'click',
      button_name: 'color_test_srevert',
      color_test_session_instance: this.colorSamplingService.instanceSession,
      color_test_session: this.colorSamplingService.session
    });
    this.reverting = true;
    this.colorSamplingService.testing = true;
    await this.colorSamplingService.aAS.revertImageRGB(null);
    this._fixes = [];
    this.reverting = false;
    this.colorSamplingService.testing = false;
    this.colorSamplingService.fixed = false;
  }

  async untilFinalLoaded() {
    await this.utils.setTimeout();
    if (!this.colorSamplingService.aAHS.isViewerFullyLoaded)
      await this.colorSamplingService.aAS.broadcastViewerFullyLoaded();
    if (!this.colorSamplingService.aAHS.isViewerFullyLoaded)
      await this.untilFinalLoaded();
  }

  public async applyPreset(): Promise<void> {
    await this.untilFinalLoaded();
    if (this._currentPreset) {
      this.colorSamplingService.aAHS.currentPreset = this._currentPreset;
      await this.colorSamplingService.aAHS.applyPreset();
    }
    else
      await this.colorSamplingService.applyDefaultScene();
    this.colorSamplingService.testing = false;
    this.colorSamplingService.saved = true;
    this.saving = false;
  }

  private setBrightnessFailedMessage(): void {
    const status = this.sample.deltaLAB.lightnessDiff < 0 ? 'dark' : 'light';
    this.brightnessFailMessage = `Brightness level is too ${status}`;
  }

  private setHueFailedMessage(): void {
    const deltaA = Math.abs(this.sample.deltaLAB.channelADiff) > 1 ? this.sample.deltaLAB.channelADiff < 0 ? 'green' : 'red' : '';
    const deltaB = Math.abs(this.sample.deltaLAB.channelBDiff) > 1 ? this.sample.deltaLAB.channelBDiff < 0 ? 'blue' : 'yellow' : '';

    if (deltaA && deltaB) {
      this.hueFailMessage = `Hue is too ${deltaA} & ${deltaB}`;
    } else if (deltaA) {
      this.hueFailMessage = `Hue is too ${deltaA}`;
    } else {
      this.hueFailMessage = `Hue is too ${deltaB}`;
    }
  }

  private clearOnJobRefreshSub() {
    if (this.onJobRefreshSub) {
      this.onJobRefreshSub.unsubscribe();
      this.onJobRefreshSub = null;
    }
  }

  ngOnDestroy() {
    this.clearOnJobRefreshSub();
    this.colorSamplingService.onDestroy();
  }
}
