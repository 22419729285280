import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-color-sampling-help-modal',
    templateUrl: './color-sampling-help-modal.component.html',
    styleUrls: ['./color-sampling-help-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColorSamplingHelpModalComponent {
  public bullets = [
    {
      title: 'Adjust in Photoshop',
      description: 'Using the Levels and Brightness/Contrast adjustment layers to make adjustments.'
    },
    {
      title: 'Update GLTF',
      description: 'Once you have matched and validated the colors, update the diffuse map in the GLTF folder and re-upload it.'
    },
    {
      title: 'Keep trying!',
      description: 'Keep repeating this process until you are satisfied with the result.'
    }
  ];

  constructor(private dialogRef: MatDialogRef<ColorSamplingHelpModalComponent>) { }

  public onClose(): void {
    this.dialogRef.close();
  }
}
