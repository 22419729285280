import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    standalone: false
})
export class ProgressBarComponent implements OnInit {
  @Input() percentage: number;
  constructor() { }

  ngOnInit() {
  }

}
