import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';
import { FileWrap } from '../file';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FileUploadComponent implements OnInit {
  @Output() filesChange = new EventEmitter<Array<FileWrap>>();
  @Input() miltiple: boolean;
  @Input() accept: string;
  @Input('drop-mode') dropMode: boolean;
  @Input() text: string;
  @Input() round: boolean;
  @Input('additional-data') additionalData: any;
  @Input() disabled: boolean;
  @Input('ignore-base64') ignoreBase64: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() icon: string;
  @Input() width: number;
  @Input() height: number;
  @Input('invoke-on-click') invokeOnClick: boolean;
  private filesCount: number;
  private files: Array<FileWrap>;
  public msg: string;
  public btnStyle: { [key: string]: string; };
  public isDragging: boolean;
  constructor(
    private utils: UtilsService
  ) {
    this.filesChange = new EventEmitter<Array<FileWrap>>();
    this.filesCount = 0;
    this.btnStyle = {};
  }

  ngOnInit() {
    if (typeof this.width === 'number')
      this.btnStyle['width'] = this.width + 'px';
    if (typeof this.height === 'number')
      this.btnStyle['height'] = this.height + 'px';
    // switch (this.accept) {
    //   case 'images': {
    //     this.acceptType = 'image/*'
    //     break;
    //   }
    //   case 'compres': {
    //     this.acceptType = '.zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed'
    //     break;
    //   }
    //   default: {
    //     this.acceptType = ''
    //   }
    // }
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   debugger;
  // }

  private onFilerender(file: any, reader?: any) {
    let suffix = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (this.accept == '.zip' && suffix.toLowerCase() != 'zip') {
      this.msg = 'file extension should be *.ZIP only!';
      return;
    }
    this.files.push({
      base64: reader ? reader.result : null,
      name: file.name.substring(0, file.name.lastIndexOf('.')),
      suffix: suffix,
      file: file,
      path: file.path ? file.path.replace(file.name, '') : file.path,
      additionalData: this.additionalData,
      icon: this.utils.getIconByUrl(file.name)
    });
    // let size = file.size / 1024 / 1024;
    // if (size > 30) {
    //   alert('max file size is 30MB, ' + file.name + ' size is ' + size + 'MB');
    //   this.msg = '';
    //   return;
    // }
    if (this.files.length == this.filesCount) {
      this.msg = '';
      // if (!this.dropMode) {
      //   this.files.forEach((file) => {
      //     this.msg += file.name + '.' + file.suffix + ',';
      //   });
      //   if (this.files.length > 0)
      //     this.msg = this.msg.substring(0, this.msg.length - 1);
      // }
      this.filesChange.emit(this.files);
    }
    this.fileInput.nativeElement.value = '';
  }

  private renderFile(file: File) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.onFilerender(file, reader);
    }, false);
    reader.readAsDataURL(file);
  }

  onClick() {
    if (this.invokeOnClick)
      this.fileInput.nativeElement.click();
  }

  onFilesChange(event: any) {
    this.msg = 'processing . . .';
    this.filesCount = event.srcElement.files.length;
    this.files = [];

    for (let file of event.srcElement.files) {
      if (!this.ignoreBase64)
        this.renderFile(file);
      else
        this.onFilerender(file);
    }
  }

  // onDrop(evt: any) {
  //   console.log('onDrop()');
  //   this.prevent(evt);
  //   this.isDragging = false;
  //   this.fileInput.nativeElement.files = evt.dataTransfer.files;

  //   this.msg = 'processing . . .';
  //   this.filesCount = evt.dataTransfer.files.length;
  //   this.files = [];

  //   for (let file of evt.dataTransfer.files) {
  //     if (!this.ignoreBase64)
  //       this.renderFile(file);
  //     else
  //       this.onFilerender(file);
  //   }
  // }

  // onDragEnd(evt: MouseEvent) {
  //   console.log('onDragEnd()');
  //   this.isDragging = false;
  //   this.prevent(evt);
  // }

  // onDragStart(evt: MouseEvent) {
  //   this.isDragging = true;
  //   console.log('onDragsSart()');
  //   this.prevent(evt);
  // }
  
  // onDrag(evt: MouseEvent) {
  //   this.prevent(evt);
  // }

  // onDragOver(evt: MouseEvent) {
  //   console.log('onDragOver');
  //   this.prevent(evt);
  //   this.isDragging = true;
  // }

  // onDragEnter(evt: MouseEvent) {
  //   console.log('onDragEnter');
  //   this.prevent(evt);
  //   this.isDragging = true;
  // }

  // onDragLeave(evt: MouseEvent) {
  //   console.log('onDragLeave');
  //   this.prevent(evt);
  //   this.isDragging = false;
  // }

  // prevent(evt: MouseEvent) {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }
}