import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'hexa-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            // useExisting: ToggleComponent,
            multi: true
        }
    ],
    standalone: false
})
export class ToggleComponent implements ControlValueAccessor {
  @Output() toggle = new EventEmitter<boolean>();
  @Input() disabled: boolean;
  private _value: number

  constructor() { }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.onChange(this.value);
    this.onTouched(this.value);
  }

  public onToggle(state: MatSlideToggleChange): void {
    this.toggle.emit(state.checked);
  }

  onChange: any = () => {}
  onTouched: any = () => {}

  writeValue(val: number): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
