<div class="wrap body-bg"
  [ngClass]="{'full': isFullscreen(), 'sidebar-open': isSidebarOpen, 'messages-page': messagesPage}">
  <ng-container *ngTemplateOutlet="feedbackMode ? feedbackTemplate : (showChatTopics ? chatTopics : chat)"></ng-container>
</div>

<ng-template #feedbackTemplate>
  <div class="feedback-wrap pos-rel disp-flex flex-column">
    <button class="pos-abs cancel" mat-icon-button (click)="toggleFeedback(false)">
      <mat-icon>close</mat-icon>
    </button>
    <h3 class="text-center h1 capitalize">
      chat session feedback
    </h3>
    <div>
      Please provide a feedback for your last chat session
    </div>
    <app-ranking [star-width]="28" [edit-mode]="true" class="center-me text-center" [max]="5"
      (on-change)="onRankChange($event)"></app-ranking>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Leave a comment</mat-label>
        <textarea class="scrollbar" [(ngModel)]="feedbackComment" matInput placeholder="Provide a more detailed feedback about your recent experience"></textarea>
      </mat-form-field>
    </div>
    <div>
      <button (click)="sendFeedback()" mat-raised-button color="primary" [disabled]="!!!sessionRank">
        send feedback
      </button>
    </div>
  </div>
  
</ng-template>

<ng-template #existingSessionType>
  <app-emojis class="scrollbar fade-in body-bg" (on-update)="addEmoji($event)" *ngIf="showEmojis"></app-emojis>
    <button fxFlex="40px" mat-icon-button (click)="showEmojis = !showEmojis">
      <mat-icon>tag_faces</mat-icon>
    </button>
    <!-- <textarea class="scrollbar" fxFlex #text matInput cols="30" rows="3" placeholder="Type a message"
      [(ngModel)]="message.body" (paste)="attach($event)"></textarea> -->
    <textarea class="scrollbar" fxFlex #text matInput cols="30" rows="3" placeholder="Type a message" *ngIf="history"
      [(ngModel)]="chatService.messageBody[history.id]" (paste)="attach($event)" name="chat_message"></textarea>
    <button mat-icon-button fxFlex="40px" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <app-file-upload class="pos-abs top-0 left-0 w-100" [invoke-on-click]="true" [icon]="'attach_file'"
          [text]="'share a file'" [miltiple]="false" (filesChange)="onFilesChange($event)">
        </app-file-upload>
        <!-- <span>share a file</span> -->
      </button>
      <button mat-menu-item (click)="chatService.toggleUnread(history)">
        <mat-icon [ngClass]="{'contrast-text': history.read, 'accent-color': !history.read}" class="default-color">
          markunread_mailbox</mat-icon>
        <span class="default-color bold uppercase">mark as {{history.read ? 'unread' : 'read'}}</span>
      </button>
    </mat-menu>
    <button fxFlex="40px" mat-icon-button (click)="send()" class="send-btn">
      <mat-icon>send</mat-icon>
    </button>
</ng-template>

<ng-template #noSessionType>
  <div class="chat-conversation-start-new-session-wrapper">
    <button class="chat-conversation-start-new-session" (click)="getSessionTopics()">{{'start a new session' | uppercase}}</button>
  </div>
</ng-template>

<ng-template #chat>
  <h4 class="head">
    <mat-progress-bar class="waiting" *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
    <span class="user-wrap ellipsis">
      <span class="status" [ngClass]="{'online': isOnline()}"></span>
      <span *ngIf="currentUserTyping">
        {{currentUserTyping.name}} is typing . . .
      </span>
      <span *ngIf="!currentUserTyping" class="users ellipsis">
        <ng-container *ngIf="history.topic">{{history.topic}}</ng-container>
        <ng-container *ngIf="!history.topic">
          <ng-container
            *ngFor="let artistsuserchat of history.artists_users_chats | exclude:'user_id':auth.user.id; let last = last">
            <ng-container *ngIf="artistsuserchat.artists_users[0].id != auth.user.id">
              {{artistsuserchat.artists_users[0].name}}
              <ng-container *ngIf="!last">,</ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </span>
    </span>
    <span class="float-op-dir btns-wrap pos-rel">
      <!-- <button mat-icon-button color="accent" (click)="toggleVideo()" color="primary">
        <mat-icon>videocam</mat-icon>
      </button>
      <button mat-icon-button color="accent" (click)="toggleVideo()" color="primary">
        <mat-icon>mic</mat-icon>
      </button> -->
      <button mat-icon-button (click)="toggleFullscreen()"
        [matTooltip]="isFullscreen() ? 'exit fullscreen' : 'enter fullscreen'" fxHide fxShow.gt-sm="true"
        class="contrast-text">
        <mat-icon class="va-top" *ngIf="isFullscreen()">fullscreen_exit</mat-icon>
        <mat-icon class="va-top" *ngIf="!isFullscreen()">fullscreen</mat-icon>
      </button>
      <button mat-icon-button (click)="toggleMinimize()"
        [matTooltip]="chatService.isMinimize[history.id] ? 'expand' : 'minimize'" class="contrast-text">
        <mat-icon class="va-top" *ngIf="chatService.isMinimize[history.id]">expand</mat-icon>
        <mat-icon class="va-top" *ngIf="!chatService.isMinimize[history.id]">remove</mat-icon>
      </button>
      <button mat-icon-button matTooltip="new artist" class="contrast-text" *ngIf="isNewUser">
        <mat-icon class="va-top">fiber_new</mat-icon>
      </button>
      <button mat-icon-button matTooltip="related job" class="contrast-text" *ngIf="history.job_id"
        (click)="navToJob(history.job_id)">
        <mat-icon class="va-top">assignment</mat-icon>
      </button>
      <mat-icon matRipple class="close" (click)="close()" [matTooltip]="'close'">close</mat-icon>
    </span>
  </h4>
  <div class="body">
    <span class="chat-conversation-resolved" *ngIf="canResolve()">
      <button mat-icon-button [matMenuTriggerFor]="sessionMenu" aria-label="session menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #sessionMenu="matMenu">
        <!-- <div mat-menu-item><b>{{chatService.chatTopicsDictionary[history.type_id]}}</b></div> -->
        <button mat-menu-item (click)="resolveChat()" class="capitalize">resolve session</button>
        <button mat-menu-item (click)="editSession()" class="capitalize">edit session</button>
      </mat-menu>
    </span>
    <ul class="normalize scrollbar messages" #container>
      <li *ngFor="let group of chatsGroups" class="row">
        <div class="date-wrap float-dir w-100">
          <mat-divider></mat-divider>
          <p class="text2 body-bg">
            {{group.created_at | moment : 'format' : 'EEEE, MMMM do' }}
          </p>
        </div>
        <div>
          <div *ngFor="let message of group.items;let last = last" class="box-shadow item"
            [ngClass]="{'me float-op-dir box-bg': message.user_id == auth.user.id, 'float-dir alt-bg': message.user_id != auth.user.id, 'pointer': textToSpeech.isSupported, 'dummy': !message.id}"
            (click)="read(message, $event)">
            <span class="date"
              [ngClass]="{'float-op-dir box-bg gray8': message.user_id == auth.user.id, 'float-dir alt-bg': message.user_id != auth.user.id, 'speak': readInProgress[message.id]}">
              <!-- ({{message.created_at | moment:'fromNow':'YYYY-MM-DD HH:mm:ss.SSS':tick}}) -->
              <ng-container *ngIf="chatService.usersDictionary[message.user_id]">
                <span class="status" *ngIf="message.user_id != auth.user.id"
                  [ngClass]="{'online': chatService.usersDictionary[message.user_id].available}"></span>
                {{chatService.usersDictionary[message.user_id].name}}
              </ng-container>
              ({{message.created_at | moment:'fromNow':"yyyy-MM-dd'T'HH:mm:ss.SSSXXX":tick}})
              <!-- <mat-icon *ngIf="message.read" class="read">done_all</mat-icon> -->
            </span>
            <div *ngIf="message.message_type == 0" class="float-dir clear no-break"
              [innerHTML]="message.body | linkify:'blank'"></div>
            <a *ngIf="message.message_type == 3" class="float-dir clear no-break" [href]="message.body" target="_blank">
              <img *ngIf="!message.id || utils.getIconByUrl(message.body) == 'photo'" [src]="message.body"
                (load)="onImgLoad(message.body)" alt="attachment">
              <div *ngIf="message.id && utils.getIconByUrl(message.body) != 'photo'"
                [innerHTML]="message.body | linkify:'blank'"></div>
            </a>
            <ng-container *ngIf="last">
              {{renderEnd()}}
            </ng-container>
          </div>
        </div>
      </li>
      <li *ngIf="lastSessionToFeedback" class="row">
        <div class="date-wrap float-dir w-100">
          <mat-divider></mat-divider>
          <p class="text2 body-bg">
            Session Ended
          </p>
        </div>
        <div>
          <div class="box-shadow item box-bg float-dir pointer">
            <span class="date float-op-dir box-bg gray8 float-dir alt-bg">
                <span class="status online"></span>system
            </span>
            <div class="float-dir clear no-break" (click)="toggleFeedback(true)">
              To provide a feedback for your last chat session click here
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="chat-footer box-shadow" [ngClass]="{'shift': isShift}" fxLayout="row"
    fxLayoutAlign="space-between center">
    <ng-container *ngTemplateOutlet="(showStartNewSession) ? noSessionType : existingSessionType"></ng-container>
  </div>
</ng-template>

<ng-template #chatTopics>
  <mat-progress-bar class="waiting topics" *ngIf="isWaiting" mode="indeterminate"></mat-progress-bar>
  <div class="chat-conversation-topics" *ngIf="chatService.chatTopics">
    <mat-icon matRipple class="pointer" (click)="showChatTopics = false" [matTooltip]="'abort'">close</mat-icon>
    <div class="text-center">Select the session topic:</div>
    <button class="chat-conversation-topic" *ngFor="let chatType of getRelevantTopics(chatService.chatTopics)" (click)="onTopicClick(chatType)">
      <div class="chat-conversation-topic-text" [title]="chatType.description">
        {{chatType.description}}
      </div>
    </button>
  </div>
</ng-template>
