import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AssetAdjustmentsHelperService } from '../../ui-components/asset-adjustments-helper.service';

@Component({
    selector: 'hexa-model-opacity',
    templateUrl: './model-opacity.component.html',
    styleUrls: ['./model-opacity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ModelOpacityComponent {
  constructor(public assetAdjustmentsHelperService: AssetAdjustmentsHelperService) { }
}
