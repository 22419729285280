import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-chip-input',
    templateUrl: './chip-input.component.html',
    styleUrls: ['./chip-input.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false
})
export class ChipInputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() type: string;
  @Output('input-change') inputChange: EventEmitter<string>;
  @ViewChild('input') input: ElementRef;
  constructor() {
    this.inputChange = new EventEmitter<string>();
  }

  ngOnInit() {
    this.type = this.type || 'text';
    this.placeholder = this.placeholder || '';
    // Observable.fromEvent(this.input.nativeElement, 'keyup')
    //   .subscribe((res: any) => {
    //     if (res.keyCode == 13)
    //       this.add();
    //   });
  }

  keyup(evt: KeyboardEvent) {
    if (evt.keyCode == 13)
      this.add(evt.target);
  }

  keydown(evt: KeyboardEvent) { // hotfix for angular 8
    if (evt.keyCode == 13)
      evt.preventDefault();
  }

  add(target?: any) {
    let input = target ? target : this.input.nativeElement;
    this.inputChange.emit(input.value);
    input.value = '';

    // this.inputChange.emit(this.input.nativeElement.value);
    // this.input.nativeElement.value = '';
  }

}
