<div class="wrap scrollbar pos-rel disp-flex space-between flex-1"
     [ngClass]="{'decimate-polygons': currentMode === resourceMode.DECIMATE_POLYGONS, 'color_sampling': currentMode === resourceMode.COLOR_SAMPLING, 'multi': jobService.job?.UI?.isMulti}">
  <button mat-icon-button color="accent" class="close pos-abs" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <div class="model-side scrollbar">
    <div class="top-menu disp-flex flex-end">
      <button mat-button class="thin ghost primary invert back-to-adjustments-btn" *ngIf="alignment" (click)="closeAlignment()">
        <mat-icon>chevron_left</mat-icon>
        Back to adjustments
      </button>

      <ng-container *ngIf="!alignment">
        <div *ngIf="currentMode !== resourceMode.COLOR_SAMPLING && currentMode !== resourceMode.ALIGNMENT && jobService.job?.UI?.isMulti" class="variations-wrap default-duration">
          <span *ngFor="let item of jobService.job.artists_jobs_items; let i = index;">
            <button mat-fab (click)="setCurrentItemById(item.id)" class="fab-btn no-marg"
                    [ngClass]="{'current': item.id === jobService.currentArtistsjobitemId}"
                    [style.backgroundImage]="'url(' + item.artists_items[0].artists_items_data[0].small_image_url + ')'"
                    [disabled]="isAlignmentMode || currentMode === resourceMode.DECIMATE_POLYGONS">
              <span *ngIf="item.UI?.hasApproved" class="icon-v va-text-top primary-color has-approved pos-abs"
                    [ngClass]="{'first': item.UI?.hasFinalApproved}"><span class="path2"></span></span>
              <span *ngIf="item.UI?.hasFinalApproved"
                    class="icon-v va-text-top primary-color has-approved pos-abs second">
                <span class="path2"></span>
              </span>
            </button>
          </span>
          <span>
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="jobService.duplicateLightsAdjustments()">Lights</button>
              <button mat-menu-item (click)="jobService.duplicateHDRIAdjustments()">HDRI</button>
            </mat-menu>
            <button mat-fab [matMenuTriggerFor]="appMenu" class="fab-btn copy" color="accent"
                    matTooltip="duplicate adjustments"
                    [disabled]="isAlignmentMode || currentMode === resourceMode.DECIMATE_POLYGONS">
              <mat-icon aria-label="duplicate">file_copy</mat-icon>
            </button>
          </span>
        </div>

        <div class="disp-flex flex-align-center flex-content-center" *ngIf="isAlignmentMode">
          <button (click)="onAlignmentImageSelected()" [disabled]="isAlignmentNextButtonDisabled"
                  type="button" mat-raised-button color="primary">
            Next
          </button>
        </div>

        <div class="disp-flex flex-end pos-rel">
          <app-resource-dropdown *ngIf="show3dAssetsDropdown" class="assets-dropdown" (selected)="onResourceSelect($event)"
                                 [resources]="jobService.currentArtistsjobitem.artists_jobs_resources"
                                 [selectedResource]="jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]"
          ></app-resource-dropdown>
          <mat-menu #advancedMenu="matMenu">
            <ng-container *ngFor="let rm of allResourceModes">
              <button mat-menu-item role="menuitemradio" *ngIf="resourceModesActive[rm.key]"
                      (click)="changeMode(rm.key)" [ngClass]="{'bg-active': rm.key === currentMode}">
                <mat-icon>{{resourceModesIcons[rm.key]}}</mat-icon>
                <span>{{rm.value}}</span>
              </button>
            </ng-container>
          </mat-menu>

          <button *ngIf="allResourceModes.length" mat-icon-button [matMenuTriggerFor]="advancedMenu"
                  aria-label="Menu for advanced options" class="advanced-options">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <div class="upper-info" *ngIf="!alignment && currentMode !== resourceMode.DECIMATE_POLYGONS">
      <div *ngIf="isColorSampleMode" class="tutorial-hint">
        <div>To compare your model’s colors values to the reference image,<br>
          first align the model and then take a sample.
        </div>
        <a class="hexa-link" (click)="colorSampleTutorial()">Need help?</a>
      </div>

      <div *ngIf="isAlignmentMode" class="tutorial-hint">
        <div>Place a dot on the image reference and then place a matching dot on the
          3D model, in a mirror view.
        </div>
        <a class="hexa-link" (click)="modelAccuracyTutorial()">Need help?</a>
      </div>
    </div>

    <div class="model-container">

      <hexa-loader *ngIf="showLoader"></hexa-loader>

      <mat-card *ngIf="alignment; else modelImageCompare" class="flex-1">
        <mat-card-header>
          <mat-card-subtitle>created {{alignment.created_at | moment:'fromNow':"yyyy-MM-dd'T'HH:mm:ss.SSSXXX"}}
          </mat-card-subtitle>
        </mat-card-header>
        <img [src]="alignment.url" alt="alignment screenshot" class="adjustment-screenshot">
      </mat-card>

      <ng-template #modelImageCompare>
        <div class="align-helper pos-rel" *ngIf="isAlignmentMode && !showAlignmentCompareResult && referenceImageChosen">
          <app-collision-step-indicator></app-collision-step-indicator>
        </div>
        <app-model-image-compare *ngIf="jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]"
                                 class="pos-rel flex-1"
                                 [manual-zoom]="true"
                                 [media-tag]="mediaTag"
                                 [image]="images[currentImageIndex]"
                                 [model]="model"
                                 [allow-screenshot]="true"
                                 [zoom]="true"
                                 [mode]="feedbackMode"
                                 [opacity]="aHelperService.modelOpacity"
                                 [opacity-animation]="aHelperService.modelOpacityAnimation"
                                 [init-screenshot]="screenshot"
                                 [alignment-mode]="isAlignmentMode"
                                 [colorSampleMode]="isColorSampleMode"
                                 [generate-screenshot]="generateScreenshot"
                                 [hide-actions]="true"
                                 [pixel-test]="currentMode === resourceMode.COLOR_SAMPLING && colorSamplingService.pixelsTest"
                                 [view-only]="true"
                                 (on-position)="setPosition($event)"
                                 (on-screenshot)="onScreenshot($event)"
                                 (on-iframe)="setAdjustments($event)"
                                 (on-alignment-origin)="setAlignmentOrigin($event)">
        </app-model-image-compare>
        <div *ngIf="!jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]"
             class="no-model">
          no resources has been uploaded for this item . . .
        </div>
        <div *ngIf="showAlignmentCompareResult" class="alignment-result alt-bg flex-1 h-100">
          <div>
            <div class="alignment-result-score" *ngIf="isSU">
              You have reached {{alignmentResponseScore}}% accuracy.
            </div>
            <div class="alignment-result-title">
              <span *ngIf="isCollisionSuccess; else failTitle" class="success">Well Done!</span>
              <ng-template #failTitle>
                <span class="fail">Failed accuracy test</span>
              </ng-template>
            </div>
            <div class="alignment-result-description">
              <span *ngIf="isCollisionSuccess; else collisionFailed">Your model’s geometry is highly compatible with the reference image.</span>
              <ng-template #collisionFailed>
                <span>Seems like your model’s geometry needs some fine-tunings.</span>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>

    </div>

    <div class="lower-info pos-rel" *ngIf="!alignment && currentMode !== resourceMode.DECIMATE_POLYGONS">
      <div *ngIf="isColorSampleMode || isAlignmentMode" class="model-rotate-hint">
        <div *ngIf="feedbackMode !== CombineImagesMode.MODEL_ON_TOP">
          <div *ngIf="showAlignmentCompareResult" class="disp-flex flex-align-center flex-content-center">
            <hexa-slider [(ngModel)]="collisionService.renderOpacity"></hexa-slider>
          </div>
        </div>
        <div class="disp-flex flex-align-center flex-content-center">
          <ng-container *ngIf="isColorSampleMode">
            <img src="assets/rotate_cube.svg">
            Click & hold ‘Space-key’ to rotate
          </ng-container>
        </div>
      </div>
    </div>

    <div class="bottom-gallery" *ngIf="!alignment">
      <div class="pos-rel images-wrap flex-1 w-50" *ngIf="sliderImages.length && feedbackMode !== CombineImagesMode.MODEL_ONLY">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
          <div fxFlex fxFlex.xs="100" class="pos-rel max-w-100">
            <app-simple-carousel-slider [width]="66" [height]="66" [images]="sliderImages" [currentIndex]="currentImageIndex"
                                        [ngClass]="{'inactive': collisionService.alignmentSection > collisionService.ALIGNMENT_SECTION.CHOOSE}"
                                        (index-change)="setIndex($event)"
                                        [disabled]="collisionService.alignmentSection > collisionService.ALIGNMENT_SECTION.CHOOSE"></app-simple-carousel-slider>
          </div>
        </div>
      </div>
      <div class="flex-1 w-50" *ngIf="feedbackMode === CombineImagesMode.SIDE_BY_SIDE">
      </div>
      <div *ngIf="currentMode === resourceMode.ADJUSTMENTS && feedbackMode !== CombineImagesMode.MODEL_ONLY" class="disp-flex flex-align-center flex-content-center h-100">
        <button type="button" mat-icon-button (click)="getScreenshot()" class="camera-btn" [ngClass]="{'spinner round': alignmentProcess}"
                matTooltip="Save current model alignment. The alignment will be associated with the selected reference image.">
          <!-- <mat-spinner diameter="50" class="alignment-process" *ngIf="alignmentProcess"></mat-spinner> -->
          <mat-icon>photo_camera</mat-icon>
        </button>
      </div>
    </div>

    <app-resource-polygon-reduction *ngIf="currentMode === resourceMode.DECIMATE_POLYGONS"
                                    [resource]="jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]"
                                    [artist-job-resource]="jobService.currentArtistsjobitem"
                                    (on-reduce)="onReduce($event)" class="disp-block">
    </app-resource-polygon-reduction>
  </div>

  <div class="panel-side" *ngIf="currentMode !== resourceMode.DECIMATE_POLYGONS">
    <hexa-button-toggle-group [buttons]="combineImagesModeToggles" [(ngModel)]="feedbackMode"
                              [disabled]="isAlignmentMode || currentMode === resourceMode.DECIMATE_POLYGONS"
                              aria-label="position"></hexa-button-toggle-group>

    <div *ngIf="adjustments" class="disp-flex flex-column flex-1 panel-side__content"
         [ngClass]="{ 'disp-none': currentMode === resourceMode.DECIMATE_POLYGONS }">
      <div *ngIf="currentMode === resourceMode.ADJUSTMENTS" class="opacity-wrap disp-flex">
        <div class="opacity-wrap" fxLayout="row" fxLayoutAlign="start center">
          <div class="desc" fxFlex>model opacity</div>
          <mat-checkbox color="primary" [(ngModel)]="aHelperService.modelOpacityAnimation" matTooltip="opacity animation"></mat-checkbox>
          <mat-slider fxFlex role="slider" aria-label="opacity" [min]="0" [max]="1" [step]="0.05" discrete>
            <input [(ngModel)]="aHelperService.modelOpacity" matSliderThumb>
          </mat-slider>
        </div>
      </div>
      <app-collision-status *ngIf="isAlignmentMode" (closeDialog)="closeDialog()"></app-collision-status>
      <app-color-sampling-status *ngIf="currentMode === resourceMode.COLOR_SAMPLING" [iframe]="iframeModel" [model]="model" (setSelectedImage)="onColorSampleImageChange($event)"
                                 (loaded)="onColorSamplingLoaded()" (closeDialog)="closeDialog()"></app-color-sampling-status>
      <app-asset-adjustments [ngClass]="{'disp-none': isAlignmentMode || currentMode === resourceMode.COLOR_SAMPLING}"
                             class="scrollbar"
                             [created-at]="jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]?.created_at"
                             [iframe-model]="iframeModel"
                             [jump-to-tab]="jumpToTab"
                             [resource]="jobService.currentArtistsjobitem.artists_jobs_resources[jobService.currentModelIndex]"
                             [export-progress]="jobService.exportProgress"
                             (on-dimensions)="jobService.onDimensions($event)"
                             [adjustments-data]="adjustmentsData"
                             (on-alignment)="onAlignment($event)"
                             [mesh-names]="jobService.currentArtistsjobitem.artists_items[0].products ? jobService.currentArtistsjobitem.artists_items[0].products[0].retailers[0].retailers_mesh_conventions : null"
                             (change-src)="jobService.changeSrc($event)"
                             [tutorial-enabled]="true"
      ></app-asset-adjustments>
    </div>
  </div>
</div>
