import { Component, OnInit } from '@angular/core';
import { StorageService } from 'ng-storage-service';

@Component({
    selector: 'app-cookies-compliance',
    templateUrl: './cookies-compliance.component.html',
    styleUrls: ['./cookies-compliance.component.scss'],
    standalone: false
})
export class CookiesComplianceComponent {
  static CCA = 'cookies-compliance-approved';
  public visible: boolean;
  constructor(
    private storage: StorageService
  ) {
    this.visible = !!!this.storage.get(CookiesComplianceComponent.CCA);
  }

  approve() {
    this.storage.set(CookiesComplianceComponent.CCA, new Date().getTime());
    this.visible = false;
  }

}
