import { Component, OnInit, OnDestroy } from '@angular/core';
import { BodyService } from '../body.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.scss'],
    standalone: false
})
export class ScrollTopComponent implements OnInit, OnDestroy {
  public isVisible: boolean;
  private subs: Array<Subscription>;
  constructor(
    private body: BodyService
  ) {
    this.subs = [];
  }

  ngOnInit() {
    this.isVisible = !!this.body.currentScrollPos;
    this.subs.push(this.body.onScrollChange.subscribe(
      currentScrollPos => this.isVisible = !!currentScrollPos
    ));
  }

  top() {
    this.body.scrollTo(null, 0);
  }

  ngOnDestroy() {
    this.subs.forEach(
      s => s.unsubscribe()
    )
  }
}
