import { Pipe, PipeTransform } from '@angular/core';
// import * as moment from 'moment';
import { format, parseISO , formatDistanceToNow, formatDistance } from 'date-fns';

@Pipe({
    name: 'moment',
    standalone: false
})
export class MomentPipe implements PipeTransform {

  transform(value: any, method?: string, f?: string, arg1?: any): any {
    const date = Date.parse(value) as any;
    if (!isNaN(date)) {
      switch (method) {
        case 'format': {
          if (f) {
            try {
              return format(date, f);
            } catch (error) {
              console.error('Invalid date format string:', f);
              return value;
            }
          }
          // Default format if none provided
          return format(date, 'MMM d, yyyy');
        }
        case 'fromNow': {
          // let format = 'YYYY-MM-DD HH:mm:ss.SSS';
          let f1 = 'yyyy-MM-dd HH:mm:ss.SSS';
          if (f) {
            f1 = f;
          }
          // const date1 = format(date, f1);
          // // const stillUtc = moment.utc(date1).toDate();
          // const stillUtc = parseISO(`${date1}Z`);
          // // const local = moment(stillUtc).local().format(format);
          // // return moment(local).fromNow(arg1);
          // // return format(local, arg1);
          return formatDistanceToNow(new Date(date), { addSuffix: true }).replace('over', '').replace('almost', '').replace('about', '').trim();
          // return format(new Date(date).toISOString(), f1);


          // const stillUtc = parseISO(`${new Date(date).toISOString()}`);
          // return formatDistanceToNow(stillUtc, { addSuffix: true });
        }
        case 'fromNow2': {
          return formatDistanceToNow(new Date(date), { addSuffix: true });
          // return moment(date).fromNow();
        }
        case 'toNow': {
          const now = new Date();
          const options = arg1 || { addSuffix: true };
          return formatDistance(date, now, options);
          // return moment(date).toNow(arg1);
        }
      }
      // return moment(date).format('MMM do yy'); //new Date(date).toUTCString();
      return format(date, 'MMM do yy');
    }
    return value;
  }
}
