import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-similar-items-compare-dialog',
    templateUrl: './similar-items-compare-dialog.component.html',
    styleUrls: ['./similar-items-compare-dialog.component.scss'],
    standalone: false
})
export class SimilarItemsCompareDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SimilarItemsCompareDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }
}
