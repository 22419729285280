import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges, AfterViewInit, SecurityContext } from '@angular/core';
import { ChatHistory, ArtistsChatMessage, ChatMessageType, UserTyping, User, ArtistSUserChat, ArtistsChatMessagesGroup, ChatHistoryType, ArtistsChatsTopic, ChatSession, ArtistsChatMessageSend } from '../../auth/user';
import { WebsocketService } from '../../communication/websocket.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';
import { BodyService } from '../../shared/body.service';
import { ChatService } from '../chat.service';
import { TextToSpeechService } from '../../shared/text-to-speech.service';
import { UtilsService } from '../../shared/utils.service';
import { FileWrap, FileUploadRequest } from '../file';
import { BroadcasterService } from 'ng-broadcaster';
import { Notification, NotificationType } from '../../shared/notifications';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { PixelsService } from 'app/shared/pixels.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { Rank } from '../rank';
import { StorageService } from 'ng-storage-service';

@Component({
    selector: 'app-chat-conversation',
    templateUrl: './chat-conversation.component.html',
    styleUrls: ['./chat-conversation.component.scss'],
    standalone: false
})
export class ChatConversationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  static TYPING_DURATION = 3000;
  public message: ArtistsChatMessage;
  public tick: number;
  public tickInterval: any;
  public currentUserTyping: User;
  public readInProgress: { [id: string]: boolean };
  public isSidebarOpen: boolean;
  public showEmojis: boolean;
  public chatsGroups: Array<ArtistsChatMessagesGroup>;
  public isNewUser: boolean;
  public isShift: boolean;
  public isWaiting: boolean;
  public showChatTopics: boolean;
  public showStartNewSession: boolean;
  public lastSessionToFeedback: number;
  public feedbackMode: boolean;
  public feedbackComment: string;
  private lastScrollTotal: number;
  private lastTS: number;
  private onChatChangeSub: Subscription;
  private subs: Array<Subscription>;
  private subOnSrcFilePast: Subscription;
  private inserted: boolean;
  private startTyping: number;
  private loadedImages: { [id: string]: boolean };
  private sessionRank: number;
  @ViewChild('text') text: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() history: ChatHistory;
  @Input('messages-page') messagesPage: boolean;
  @Output('on-change') onChange: EventEmitter<ChatHistory>;

  constructor(
    public auth: AuthService,
    public textToSpeech: TextToSpeechService,
    public chatService: ChatService,
    private websocket: WebsocketService,
    private bodyService: BodyService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    private sanitizer: DomSanitizer,
    private pixels: PixelsService,
    private rolesHelper: RolesHelperService,
    private storage: StorageService
  ) {
    this.subs = [];
    this.loadedImages = {};
    this.readInProgress = {};
    this.onChange = new EventEmitter<ChatHistory>();
    this.tick = 0;
    this.tickInterval = setInterval(() => {
      this.tick++;
    }, 60000);
    this.lastTS = new Date().getTime();
    this.isShift = this.bodyService.isShift;
    this.subs.push(this.chatService.onSidebarToggle.subscribe((state: boolean) => {
      this.isSidebarOpen = state;
    }));
    this.subs.push(this.broadcaster.on('shift').subscribe((state: boolean) => {
      this.isShift = state;
    }));
    this.subs.push(this.chatService.onChatSession.subscribe((session: ChatSession) => {
      if (session.chat_id == this.history.id) {
        if (session.resolved)
          this.history.session_id = null;
        else
          this.history.session_id = session.id;
        this.showStartNewSession = !!!this.history.session_id;
      }
    }));
    this.isSidebarOpen = this.chatService.getSidebarState();
    this.showEmojis = false;
  }

  getSessionName(name = this.lastSessionToFeedback) {
    return `session_feedback_${name}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.history)
      this.showChatTopics = false;
    this.shouldSelectType();
    if (changes.history && changes.history.previousValue) {
      if (changes.history.currentValue.id != changes.history.previousValue.id) {
        if (this.onChatChangeSub)
          this.onChatChangeSub.unsubscribe();
        this.init();
      }
    }
    this.setIsRead();
  }

  renderEnd() {
    if (this.inserted) {
      this.scrollDown();
      setTimeout(this.scrollDown.bind(this), 500);
    }
    this.inserted = false;
  }

  scrollDown() {
    if (this.container.nativeElement && this.container.nativeElement.children.length > 0) {
      this.chatService.isPendingRequests[this.history.id] = false;
      if (typeof this.lastScrollTotal !== 'number')
        this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
      else {
        let total = this.container.nativeElement.scrollHeight;
        let addedHeight = total - this.lastScrollTotal;
        this.container.nativeElement.scrollTop += addedHeight;
      }
      this.chatService.isPendingRequests[this.history.id] = true;
      this.setIsRead();
      this.lastScrollTotal = 0;
    }
  }

  setIsRead() {
    // if (!this.chatService.isMinimize[this.history.id] && this.chatService.historyDictionary[this.history.id]) {
    if (this.history.artists_chats_messages && this.history.artists_chats_messages[this.history.artists_chats_messages.length - 1] &&
      this.history.artists_chats_messages[this.history.artists_chats_messages.length - 1].id && !this.chatService.isMinimize[this.history.id])
      this.websocket.setIsRead(this.history.id, this.history.artists_chats_messages[this.history.artists_chats_messages.length - 1].id);
    // }
  }

  public getSessionTopics(): void {
    this.showChatTopics = true;
    this.pixels.sendPixel({
      event_value: this.utils.getMinutesFromMS(new Date().getTime() - (this.startTyping || 0)),
      chat_action: 'start_new_session',
      event: 'chat_action',
      job_id: this.history.job_id,
      chat_id: this.history.id,
      chat_type: ChatHistoryType[this.history.type_id],
      message: this.message.body,
      chat_message_type: this.message.message_type,
      chat_session_id: this.history.session_id
    });
  }

  /**
   * On select chat topic callback
   * @param chatType
   */
  public async onTopicClick(chatTopic: ArtistsChatsTopic) {
    this.isWaiting = true;
    const editMode = this.canResolve();
    if (editMode)
      await this.websocket.editSession(this.history.id, chatTopic.id);
    else
      await this.websocket.openSession(this.history.id, chatTopic.id);
    this.history.type_id = chatTopic.chat_type_id;
    this.showChatTopics = false;
    this.showStartNewSession = false;
    setTimeout(() => {
      this.subscribe();
      if (!editMode) {
        const data: Notification = {
          text: 'Typically replies within 30 minutes or we will email the response to you',
          type: NotificationType.Info,
          action: 'OK'
        };
        this.broadcaster.broadcast('notifyUser', data);
        this.chatService.messageBody[this.history.id] = `I’ve created a new ticket about “${chatTopic.description}”`;
        this.send({ is_auto_message: true });
      }
    });
    this.isWaiting = false;
    this.pixels.sendPixel({
      event_value: this.utils.getMinutesFromMS(new Date().getTime() - (this.startTyping || 0)),
      action_value: chatTopic.description,
      chat_action: 'select_topic',
      event: 'chat_action',
      job_id: this.history.job_id,
      chat_id: this.history.id,
      chat_type: ChatHistoryType[this.history.type_id],
      message: this.message.body,
      chat_message_type: this.message.message_type,
      chat_session_id: this.history.session_id
    });
  }

  navToJob(jobId: number) {
    if (jobId)
      this.utils.forceRedirectTo(`/jobs/job/${jobId}`);
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    this.subscribe();
  }

  subscribe() {
    if (!this.text || !this.text.nativeElement) return;
    if (this.text.nativeElement.getAttribute('data-subscribe')) return;
    this.text.nativeElement.setAttribute('data-subscribe', 'true');
    fromEvent(this.text.nativeElement, 'keyup')
      .subscribe((e: any) => {
        switch (e.keyCode) {
          case 13: {
            this.send();
            break;
          }
          default: {
            if (!this.startTyping)
              this.startTyping = new Date().getTime();
            let now = new Date().getTime();
            if (this.utils.isAboveTS(now, this.lastTS, ChatConversationComponent.TYPING_DURATION)) {
              this.websocket.setIsTyping(this.history.id, true);
              setTimeout(() => {
                if (this.utils.isAboveTS(now, this.lastTS, ChatConversationComponent.TYPING_DURATION)) {
                  this.websocket.setIsTyping(this.history.id, false);
                }
                this.lastTS = now;
              }, ChatConversationComponent.TYPING_DURATION);
            }
          }
        }
      });
    fromEvent(this.container.nativeElement, 'scroll')
      .pipe(debounceTime(20))
      .pipe(throttleTime(20))
      .subscribe(() => {
        let maxPercentage = 0.4;
        let total = this.container.nativeElement.scrollHeight;
        let current = this.getCurrentScrollPos();

        let position = current / total;
        if (position < maxPercentage && !this.chatService.isPendingRequests[this.history.id]) {
          this.getMore();
        }
        else if (position > maxPercentage)
          this.chatService.isPendingRequests[this.history.id] = false;
      });
    this.subs.push(this.chatService.onBeforeChatChange[this.history.id].subscribe(() => {
      this.lastScrollTotal = this.container.nativeElement.scrollHeight;
    }));
    this.subs.push(this.websocket.onUserTyping.subscribe((userTyping: UserTyping) => {
      if (userTyping.chat_id != this.history.id) return;
      let user = this.history.artists_users_chats.find(u => u.user_id == userTyping.user_id) as ArtistSUserChat;
      if (user) {
        user.typing = userTyping.typing;
        if (this.auth.user.id != user.artists_users[0].id) {
          if (user.typing)
            this.currentUserTyping = user.artists_users[0];
          else if (this.currentUserTyping && this.currentUserTyping.id == user.user_id) {
            delete this.currentUserTyping;
          }
        }
      }
    }));
  }

  init() {
    this.chatService.isPendingRequests[this.history.id] = false;
    if (!this.history.artists_chats_messages)
      this.history.artists_chats_messages = [];
    this.message = {
      chat_id: this.history.id,
      user_id: this.auth.user.id
    } as ArtistsChatMessage;

    this.chatService.chatsPagination[this.history.id] = ChatService.PAGE_SIZE;
    this.chatService.isPendingRequests[this.history.id] = true;
    this.websocket.getHistoryByChatId(this.history.id, this.chatService.chatsPagination[this.history.id]);
    this.buildChatsGroupes();

    this.onChatChangeSub = this.chatService.onChatChange[this.history.id].subscribe(() => {
      setTimeout(this.scrollDown.bind(this), 500);
      setTimeout(this.setIsRead.bind(this), 2000);
      this.buildChatsGroupes();
    });
    this.isNewUser = this.getIsNewUser();
    setTimeout(this.scrollDown.bind(this), 0);
    setTimeout(this.scrollDown.bind(this), 500);
    setTimeout(this.scrollDown.bind(this), 1000);
  }

  addEmoji(emoji: string) {
    if (!this.chatService.messageBody[this.history.id])
      this.chatService.messageBody[this.history.id] = '';
    this.chatService.messageBody[this.history.id] += emoji;
    this.showEmojis = false;
    this.focus();
  }

  /**
   * Return true if the topics list should be presented
   */
  public async shouldSelectType() {
    if (this.history?.type_id) {
      let chatTopics = this.chatService.chatTopics || await this.chatService.fetchTopics();
      const chatTopic = !!chatTopics.find((chat) => {
        return (chat.chat_type_id === this.history.type_id);
      });
      if (chatTopic && !this.history.session_id) {
        this.showStartNewSession = true;
      }
      else {
        this.showStartNewSession = false;
      }
    }
  }

  focus() {
    if (!this.bodyService.isTouch)
      this.text.nativeElement.focus();
  }

  send(options = {} as ArtistsChatMessageSend) {
    this.message.body = this.chatService.messageBody[this.history.id];
    if (this.message.body && this.message.body.trim() && !this.isShift) {
      // this.message.isDummy = true;
      this.isWaiting = true;
      this.websocket.setIsTyping(this.history.id, false);
      this.websocket.sendMessage(this.message.chat_id, this.message.body, ChatMessageType.TEXT);
      this.history.artists_chats_messages.push(this.message);
      this.message.artists_chats_messages_users = this.history.artists_users_chats as Array<any>;
      this.message.created_at = new Date();
      this.message.message_type = ChatMessageType.TEXT;
      this.buildChatsGroupes(false);
      setTimeout(this.scrollDown.bind(this));
      this.pixels.sendPixel({
        event_value: this.utils.getMinutesFromMS(new Date().getTime() - (this.startTyping || 0)),
        event: 'chat_message',
        job_id: this.history.job_id,
        chat_id: this.history.id,
        chat_type: ChatHistoryType[this.history.type_id],
        message: this.message.body,
        chat_message_type: this.message.message_type,
        is_auto_message: options.is_auto_message,
        chat_session_id: this.history.session_id
      });
      delete this.message.body;
      delete this.chatService.messageBody[this.history.id];
    }
    // if (this.startTyping) {
    // let eventType = 'chatSent';
    // if (this.chatService.isSU)
    //   eventType = 'chatSentSU';
    // else if (this.chatService.isSupport)
    //   eventType = 'chatSentSupport';
    // else if (this.chatService.isArtistAdmin)
    //   eventType = 'chatSentArtistAdmin';
    delete this.startTyping;
    // }
  }

  attach(event) {
    if (event.clipboardData && event.clipboardData.files && event.clipboardData.files[0]) {
      this.subOnSrcFilePast = this.utils.onSrcFilePast.subscribe(
        (src) => {
          this.subOnSrcFilePast.unsubscribe();
          let fileUploadRequest = {
            file: src,
            filename: 'c3d.png',
            compress: true
          } as FileUploadRequest;
          this.uploadFile(fileUploadRequest);
        }
      );
      this.utils.getSrcFromFilePast(event.clipboardData.files[0]);
    }
    else {// a fix for whitespaces on links that causes a problem when pasting a text with link and with plane-text

      // let length = this.text.nativeElement.value.length;
      // setTimeout(() => {
      //   let link = this.text.nativeElement.value.substring(length, this.text.nativeElement.value.length).trim();
      //   var urlRegex = /(https?:\/\/[^\s]+)/g;
      //   if (urlRegex.test(link) && link.indexOf(' ') != -1) {
      //     link = link.replace(/ /g, '%20');
      //     this.text.nativeElement.value = this.text.nativeElement.value.substring(0, length) + link;
      //   }
      // });
    }
  }

  onFilesChange(fileList: Array<FileWrap>) {
    let file = fileList[0];
    if (!file) return;
    let fileUploadRequest = {
      file: file.base64,
      filename: file.name + '.' + file.suffix,
      compress: this.utils.getIconByUrl(file.name + '.' + file.suffix) == 'insert_drive_file' ? false : true
    } as FileUploadRequest;
    this.isWaiting = true;
    this.uploadFile(fileUploadRequest);
  }

  onImgLoad(src: string) {
    if (this.loadedImages[src]) return;
    setTimeout(this.scrollDown.bind(this));
    this.loadedImages[src] = true;
  }

  private uploadFile(fileUploadRequest: FileUploadRequest) {
    let now = new Date();
    this.history.artists_chats_messages.push(
      {
        body: fileUploadRequest.file,
        chat_id: this.history.id,
        message_type: ChatMessageType.FILE,
        created_at: now,
        updated_at: now,
        user_id: this.auth.user.id,
        id: null
      }
    );
    this.inserted = true;
    setTimeout(this.scrollDown.bind(this), 500);
    this.utils.getUrlFromBase64(
      fileUploadRequest.file,
      undefined,
      fileUploadRequest.filename
    ).then(url => {
        this.websocket.sendMessage(this.message.chat_id, url, ChatMessageType.FILE);
        this.pixels.sendPixel({
          event: 'chat_message',
          job_id: this.history.job_id,
          chat_id: this.history.id,
          chat_type: ChatHistoryType[this.history.type_id],
          message: url,
          chat_message_type: ChatMessageType.FILE
        });
      }).catch(err => {
      const notificationData: Notification = {
        text: err || 'failure uploading file',
        type: NotificationType.Error,
        action: 'OK'
      }
      this.broadcaster.broadcast('notifyUser', notificationData);
    });
  }

  private getCurrentScrollPos(): number {
    if (this.container)
      return this.container.nativeElement.clientHeight + (this.container.nativeElement.scrollTop + this.container.nativeElement.scrollTop);
    return 0;
  }

  close() {
    delete this.chatService.isMinimize[this.history.id];
    delete this.chatService.fullscreenId;
    setTimeout(() => {
      this.history.isOpen = false;
      this.onChange.emit(this.history);
      this.chatService.saveCurrentState();
    }, 350);
    this.history.isHidden = true;
  }

  public async resolveChat() {
    this.isWaiting = true;
    this.pixels.sendPixel({
      event_value: this.utils.getMinutesFromMS(new Date().getTime() - (this.startTyping || 0)),
      chat_action: 'resolved',
      event: 'chat_action',
      job_id: this.history.job_id,
      chat_id: this.history.id,
      chat_type: ChatHistoryType[this.history.type_id],
      message: this.message.body,
      chat_message_type: this.message.message_type,
      chat_session_id: this.history.session_id
    });
    await this.chatService.closeSession(this.history.id);
    this.shouldSelectType();
    this.isWaiting = false;
  }

  public async editSession() {
    this.showChatTopics = true;
  }

  public canResolve(): boolean {
    return this.history.session_id && (this.rolesHelper.isAdminLogedin() || this.chatService.isJobChatAgent);
  }

  getMore() {
    this.chatService.isPendingRequests[this.history.id] = true;
    this.chatService.chatsPagination[this.history.id] += ChatService.PAGE_SIZE * (this.messagesPage || this.isFullscreen() ? 5 : 1);
    this.websocket.getHistoryByChatId(this.history.id, this.chatService.chatsPagination[this.history.id], '<', this.history.artists_chats_messages[0].created_at);
  }

  isFullscreen() {
    return this.chatService.fullscreenId == this.history.id;
  }

  toggleFullscreen() {
    this.chatService.isMinimize[this.history.id] = false;
    if (this.chatService.fullscreenId == this.history.id)
      delete this.chatService.fullscreenId;
    else {
      this.chatService.fullscreenId = this.history.id;
      this.getMore();
    }
    this.chatService.saveCurrentState();
  }

  toggleMinimize() {
    this.chatService.isMinimize[this.history.id] = !this.chatService.isMinimize[this.history.id];
    delete this.chatService.fullscreenId;
    this.chatService.saveCurrentState();
  }

  read(message: ArtistsChatMessage, evt: any) {
    if (message.message_type == ChatMessageType.TEXT) {
      if (evt && evt.target && evt.target.tagName && evt.target.tagName.toLowerCase() == 'a') {
        let href = evt.target.getAttribute('href');
        if (href) {
          var loc = new URL(href);
          if (loc.hostname == window.location.hostname) {
            // if (loc.hostname == "www.creators3d.com") {
            this.utils.forceRedirectTo(loc.pathname);
            // this.router.navigateByUrl(loc.pathname);
            evt.preventDefault();
          }
        }
        return;
      }
      let id = message.id;
      if (this.readInProgress[id]) {
        this.textToSpeech.cancel();
        this.readInProgress[id] = false;
      }
      else {
        this.readInProgress[id] = true;
        this.textToSpeech.say(message.body, { isHTML: true, id: id }).subscribe(
          (id: number) => this.readInProgress[id] = false
        );
      }
    }
    if (message.message_type == ChatMessageType.FILE) {
      if (evt)
        evt.preventDefault();
      window.open(message.body, '_blank');
    }
  }

  private getIsNewUser(): boolean {
    if (!this.history.artists_users_chats) return false;
    if (!this.history.artists_users_chats.length) return false;
    for (let i = 0; i < this.history.artists_users_chats.length; i++) {
      if (this.history.artists_users_chats[i].user_id == this.auth.user.id) continue;
      if (this.history.artists_users_chats[i].artists_users[0].new_user) return true;
    }
    return false;
  }

  // getRealName(user: User): string {
  //   let res = null;
  //   if (user.first_name)
  //     res = user.first_name;
  //   if (user.last_name)
  //     res = res ? res + ' ' + user.last_name : user.last_name;
  //   return res;
  // }

  private buildChatsGroupes(removeEmpty = true) {
    this.chatsGroups = [];
    let last = null as ArtistsChatMessage;
    let removeArr = [], counter = 0;
    this.history.artists_chats_messages.forEach(item => {
      item.body = this.sanitizer.sanitize(SecurityContext.HTML, item.body);
      if (removeEmpty && !item.id) {
        removeArr.push(counter);
      }
      else {
        let audit = {} as ArtistsChatMessage;
        Object.assign(audit, item);
        if (last && this.utils.sameDay(last.created_at, item.created_at) && this.chatsGroups[this.chatsGroups.length - 1]) {
          this.chatsGroups[this.chatsGroups.length - 1].items.push(audit);
        }
        else {
          this.chatsGroups.push({
            created_at: item.created_at,
            items: [audit]
          });
        }
      }
      last = item;
      counter++;
    });
    if (removeEmpty) {
      for (let i = removeArr.length - 1; i >= 0; i--)
        this.history.artists_chats_messages.splice(removeArr[i], 1);
      this.isWaiting = false;
    }
    if (!this.rolesHelper.isAdminLogedin() && this.history.artists_chats_messages.length > 1 &&
      last?.chat_session_id && !this.history.session_id &&
      !!!this.storage.get(this.getSessionName(last.chat_session_id)))
      this.lastSessionToFeedback = last.chat_session_id;
    else
      delete this.lastSessionToFeedback;
  }

  toggleFeedback(state: boolean) {
    this.feedbackMode = state;
    if (!this.feedbackMode)
      setTimeout(this.scrollDown.bind(this));
  }

  onRankChange(rank: Rank) {
    this.sessionRank = rank.value;
  }

  sendFeedback() {
    if (!this.lastSessionToFeedback || !this.sessionRank) return;
    this.pixels.sendPixel({
      event: 'artist_chat_session_feedback_score',
      chat_id: this.history.id,
      chat_session_id: this.lastSessionToFeedback,
      score: this.sessionRank,
      comment: this.feedbackComment
    });
    this.storage.set(this.getSessionName(), true);
    delete this.feedbackComment;
    delete this.sessionRank;
    delete this.lastSessionToFeedback;
    this.toggleFeedback(false);
    this.utils.notifyUser({
      text: 'Thank you for your feedback',
      type: NotificationType.Success
    });
  }

  isOnline(): boolean {
    if (!this.history.artists_users_chats) return false;
    if (!this.history.artists_users_chats.length) return false;
    for (let i = 0; i < this.history.artists_users_chats.length; i++) {
      if (this.history.artists_users_chats[i].user_id == this.auth.user.id) continue;
      if (this.history.artists_users_chats[i].artists_users[0].available) return true;
    }
    return false;
  }

  isGrayed(): boolean {
    return this.history.type_id == ChatHistoryType.JOB_CHAT && this.chatService.isJobManager && !this.chatService.isManagedByMe(this.history);
  }

  getRelevantTopics(chatTopics: Array<ArtistsChatsTopic>): Array<ArtistsChatsTopic> {
    return chatTopics.filter(c => c.chat_type_id === this.history.type_id);
  }

  ngOnDestroy() {
    clearInterval(this.tickInterval);
    if (this.onChatChangeSub)
      this.onChatChangeSub.unsubscribe();
    this.subs.forEach(s => s.unsubscribe());
  }
}
