import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'file-upload-error',
    templateUrl: 'file-upload-error.html',
    styles: [`
    .wrap {
        padding: 20px;
    }
    `],
    standalone: false
})
export class FileUploadError {

    constructor(
        public dialogRef: MatDialogRef<FileUploadError>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

}