import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeshLibraryWrapper } from '../mesh-library';

@Component({
    selector: 'app-mesh-library-dialog',
    templateUrl: './mesh-library-dialog.component.html',
    styleUrls: ['./mesh-library-dialog.component.scss'],
    standalone: false
})
export class MeshLibraryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MeshLibraryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public meshLibraryWrapper: MeshLibraryWrapper
  ) { }

  ngOnInit() {
  }

}
